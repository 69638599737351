<template>
    <div class="chart-item">        
        <div class="gender-item">
            <custom-bar-chart
                title="Location"
                :data="data"
            />
        </div>
        
        <!-- see more button -->
        <!-- (category_key === 'audience_genders_per_age' && followerAudience[category_key].length) -->
        <div
            class="see-more-button-container"
            v-if="isObject({ 'cities': true, 'states': true, 'countries': true })"
        >
            <base-button 
                bold 
                label="See more" 
                @action="modalVisible = true"
            />
        </div>
        
        <!-- see more modal -->
        <base-modal
            title="Location stats"
            :visible="modalVisible"
            @close="modalVisible = false"
            styles="max-height: 90vh; overflow-y: auto; max-width: 900px; width: 900px;"
        >
            <custom-bar-chart
                :limit="0"
                :data="data"
            />
        </base-modal>
    </div>
</template>

<script>
    import { isObject } from '../../common';
    export default {
        props: ["data"],
        data(){
            return {
                modalVisible: false,   
                isObject
            }
        }
    };
</script>
<style lang="scss" scoped>
    .chart-item{
        @apply;
        .see-more-button-container{
            @apply flex justify-center mt-2;
        }
    }
    .gender-item .grid:last-child .progress-wrapper {
        margin-left: 15px;
    }
</style>