<template>
    <div v-if="isValidObject(data)">

        <!-- header -->
        <div class="header">
            
            <!-- chart title -->
            <div class="title" v-if="isValidString(title)">{{ title }}</div>
            
            <!-- flex space -->
            <div class="w-full"></div>

            <!-- tabs -->
            <div 
                v-for="(tab, index) in computedTabs" 
                :key="`tab-${index}`" 
                class="tab" 
                :class="{'is-selected' : selectedTab === tab}" 
                @click="selectTab(tab)"
            >
                {{ tab }}
            </div>

        </div>

        <!-- content -->
        <!-- 'show-all-items' class adds max height and scroll when we are viewing all items -->
        <div 
            class="content"
            :class="{'show-all-items' : showAllItems}"
        >
            <!-- other tab content -->
            <div 
                v-for="(tab, index) in computedTabs" 
                :key="`tab-content-${index}`"
                class="tab-content"
            >
                <div 
                    v-if="selectedTab === tab"
                    class="list-container"
                >   
                    <custom-bar-chart-item 
                        v-for="(value, index) in computedData[tab]"
                        :key="index"
                        :value="value" 
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { sortArray, copyObject, isValidString, isValidArray, isValidObject } from '../../common'
    import CustomBarChartItem from './CustomBarChartItem.vue';
    export default {
        components: {
            CustomBarChartItem
        },
        props: {
            title: {},
            data: {},
            limit: {
                type: Number,
                default: 7
            }
        },

        data () {
            return {
                selectedTab: '',
                isValidString,
                isValidObject
            }
        },

        beforeMount() {
            this.setInitialSelectedTab();
        },

        computed: {

            // limit is set to 0 in just 1 instance of this component (ReportUserCardStats.vue)
            // all other instances dont have the "limit" prop defined,
            // resulting in a default limit of 7 for most of them
            showAllItems () {
                return !this.limit;
            },

            computedTabs () {
                let tabs = [];
                for (const key in this.data) {
                    const element = this.data[key];
                    // if it has valid content
                    if(isValidArray(element)){
                        tabs.push(key);
                    }
                }
                return tabs;
            },

            // will merge duplicates and sort by descending weight
            computedData() {

                let newDataObject = {};
                
                for (const key in this.data) {
                    
                    let arrayValue = copyObject(this.data[key]);

                    let newArray = [];
                    for(let element of arrayValue) {
                        // check for duplicates, by name
                        let index = newArray.findIndex(e => e.name == element.name);
                        // if found
                        if (index > -1) {
                            // if there is a duplicate by name we will sum the weight
                            let sum = newArray[index].weight + element.weight;
                            newArray[index] = { name: element.name, weight: sum };
                        } else {
                            // not found, just push it
                            newArray.push(element);
                        }
                    }
                    
                    // sort by descending weight
                    let sorted = sortArray(newArray, 'weight', 'desc');
                    
                    // now the array duplicates have been handled and the order has
                    // been set, we can cut the array depending on the limit.
                    if (this.limit) {
                        sorted = sorted.slice(0, this.limit) // get first limit by prop
                    }

                    // set the data
                    newDataObject[key] = sorted; 

                }

                return newDataObject;

            }
        },

        methods: {
            setInitialSelectedTab() {
                if(isValidObject(this.data)){
                    for (const key in this.data) {
                        const value = this.data[key];
                        // check if the value is valid, if it is, set that key as the selected tab
                        if(isValidArray(value)){
                            return this.selectedTab = key;
                        }
                    }
                }
            },
            selectTab(tab) {
                this.selectedTab = tab;
                this.$forceUpdate();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .header{
        @apply flex gap-x-1 mb-3;
        .title{
            @apply text-sm font-bold capitalize;
        }
        .tab{
            @apply capitalize cursor-pointer;
            &.is-selected{
                @apply font-bold;
            }
        }
    }
    .content{
        @apply mb-5;
        // this class adds max height and scroll when we are viewing all items
        &.show-all-items{
            @apply max-h-96 overflow-y-auto pr-4;
        }
        > .tab-content{
            @apply mt-3;
            @media (min-width: 768px) { 
                @apply w-full;
            }
            > .list-container{
                @apply flex flex-col gap-y-2;
            }
        }
    }
</style>
