var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.user.id !== 0)?_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"header"},[_c('tabs',{attrs:{"tabs":[{ name: 'Instagram top posts', active: true }, { name: 'TikTok top posts', active: true }]},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),_c('div',{staticClass:"content"},[_c('tab',{attrs:{"visible":_vm.tab == 'Instagram top posts'}},[(_vm.loading)?_c('base-icon',{attrs:{"name":"loader"}}):[(!_vm.hasTopPosts(2))?_c('div',{staticClass:"empty-state"},[_c('div',[_vm._v("No Instagram top posts found")]),_c('base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.hasNetwork(2) ? { 
                            content: 'This creator does not have an Instagram handle', 
                            classes: 'v-tooltip-black' 
                        } : {}),expression:"!hasNetwork(2) ? { \n                            content: 'This creator does not have an Instagram handle', \n                            classes: 'v-tooltip-black' \n                        } : {}"}],attrs:{"label":"Get top posts","disabled":!_vm.hasNetwork(2),"bold":"","size":"lg"},on:{"action":function($event){return _vm.fetchTopPosts(2)}}})],1):[_c('div',{staticClass:"actions"},[_c('base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.hasNetwork(2) ? { 
                                content: 'This creator does not have an Instagram handle', 
                                classes: 'v-tooltip-black' 
                            } : {}),expression:"!hasNetwork(2) ? { \n                                content: 'This creator does not have an Instagram handle', \n                                classes: 'v-tooltip-black' \n                            } : {}"}],attrs:{"label":"Refresh top posts","disabled":!_vm.hasNetwork(2),"bold":"","size":"lg"},on:{"action":function($event){return _vm.fetchTopPosts(2)}}})],1),_c('div',{staticClass:"post-list"},_vm._l((_vm.getTopPosts(2)),function(post,index){return _c('report-post-card',{key:index,staticClass:"post-card mb-4",attrs:{"is-preview":"","post":post}})}),1)]]],2),_c('tab',{attrs:{"visible":_vm.tab == 'TikTok top posts'}},[(_vm.loading)?_c('base-icon',{attrs:{"name":"loader"}}):[(!_vm.hasTopPosts(14))?_c('div',{staticClass:"empty-state"},[_c('div',[_vm._v("No TikTok top posts found")]),_c('base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.hasNetwork(14) ? { 
                            content: 'This creator does not have a TikTok handle', 
                            classes: 'v-tooltip-black' 
                        } : {}),expression:"!hasNetwork(14) ? { \n                            content: 'This creator does not have a TikTok handle', \n                            classes: 'v-tooltip-black' \n                        } : {}"}],attrs:{"label":"Get top posts","disabled":!_vm.hasNetwork(14),"bold":""},on:{"action":function($event){return _vm.fetchTopPosts(14)}}})],1):[_c('div',{staticClass:"actions"},[_c('base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.hasNetwork(14) ? { 
                                content: 'This creator does not have a TikTok handle', 
                                classes: 'v-tooltip-black' 
                            } : {}),expression:"!hasNetwork(14) ? { \n                                content: 'This creator does not have a TikTok handle', \n                                classes: 'v-tooltip-black' \n                            } : {}"}],attrs:{"label":"Refresh top posts","disabled":!_vm.hasNetwork(14),"bold":"","size":"lg"},on:{"action":function($event){return _vm.fetchTopPosts(14)}}})],1),_c('div',{staticClass:"post-list"},_vm._l((_vm.getTopPosts(14)),function(post,index){return _c('report-post-card',{key:index,staticClass:"post-card mb-4",attrs:{"is-preview":"","post":post}})}),1)]]],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }