<template>
    <div class="additional-info">

        <div class="header">
            <div class="title">Custom Fields</div>
            <div class="actions">
                <base-button
                    label="Add Info"
                    bold
                    icon="plus"
                    @action="createAdditionalInfoModalVisible = true"
                />
            </div>
        </div>
        
        <div class="content">
            <div 
                class="empty-state" 
                v-if="!isArray(userVal.additional_info)"
            >
                No additional info
            </div>
            <div 
                v-else 
                class="info-list"
            >
                <!-- view parsed info, edit info, delete them, create -->
                <div
                    class="info-item"
                    :class="{ 'active': isSelected(info) }"
                    v-for="(info, index) in userVal.additional_info" 
                    :key="`info-${index}`"
                    @click="toggleInfo(info)"
                >
                    <div class="content">
                        <div class="name">{{info.name}}</div>
                        <div 
                            class="value"
                            >{{ info.value }}</div>
                    </div>
                    <div class="actions">
                        <options-dropdown               
                            :options="[
                                {label: 'Edit', event: 'edit'},
                                {label: 'Delete', event: 'delete'}
                            ]"
                            @edit="openCreateAdditionalInfoModal(info)"
                            @delete="deleteAdditionalInfoIntent(info.id)"
                        />
                    </div>
                </div>

            </div>
        </div>

        <create-additional-info-modal
            :visible="createAdditionalInfoModalVisible"
            :user-id="user.id"
            :current-additional-info="targetAdditionalInfo"
            @reload="getAdditionalInfo()"
            @close="closeCreateAdditionalInfoModal()"
        />

    </div>
</template>
<script>
import CreateAdditionalInfoModal from './CreateReportInfluencerListCustomFieldsCreateModal.vue';
import { isArray, notifyCatchError } from '@/common';

export default {
    props: ['user'],
    components: {
        CreateAdditionalInfoModal
    },
    watch: {
        user(val){
            this.userVal = val;
        }
    },
    data(){
        return {
            userVal: this.user,
            selectedAdditionalInfo: [],
            createAdditionalInfoModalVisible: false,
            targetAdditionalInfo: null,
            isArray
        }
    },
    methods: {

        toggleInfo(info){
            if(this.isSelected(info)){
                this.selectedAdditionalInfo = this.selectedAdditionalInfo.filter(element => element.id !== info.id);
            }else{
                this.selectedAdditionalInfo.push(info);
            }
            this.$emit('change', this.selectedAdditionalInfo);
        },

        isSelected(info){
            const found = this.selectedAdditionalInfo.find(element => element.id == info.id);
            if(found){
                return true;
            }
            return false;
        },

        // probably wont need this
        getAdditionalInfo(){
            this.loading = true;
            this.$http.get(`/user-additional-info/${this.user.id}?type=user`).then(({ data }) => {
                this.userVal.additional_info = data                
            }).catch(err => {
                console.log('getAdditionalInfo error', err);
            }).finally(() => {
                this.loading = false;
            });
            return;
        },

        deleteAdditionalInfoIntent(id){
            this.$swal.fire({
                title: 'Are you sure you want to delete this information for this creator?',
                text: `Changes made will also be reflected on this creator's reports`,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.deleteAdditionalInfo(id)
                }
            })
        },

        async deleteAdditionalInfo(id){
            this.loading = true;
            this.$http.delete(`/user-additional-info/${id}`).then(async (res) => {
                if(res.data){
                    this.$notify({title: 'Info deleted successfully', text: '', type: 'success'})
                    this.getAdditionalInfo();
                }
            }).catch((err) => {
                console.log('deleteAdditionalInfo error', err)
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false
            })
        },
        
        openCreateAdditionalInfoModal(info){
            this.targetAdditionalInfo = info;
            this.createAdditionalInfoModalVisible = true;
        },

        closeCreateAdditionalInfoModal(){
            this.targetAdditionalInfo = null;
            this.createAdditionalInfoModalVisible = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .additional-info{
        > .header{
            @apply w-full mb-4 flex justify-between items-center;
            > .title{
                @apply font-bold text-h5;
            }
        }
        > .content{
            @apply mt-8 mb-10;
            > .empty-state{
                @apply;
            }
            > .info-list{
                @apply flex flex-wrap gap-3;
                > .info-item{
                    @apply py-3 px-6;
                    width: 320px;
                    @apply flex gap-x-10 justify-between items-center; 
                    @apply cursor-pointer rounded-lg transition-colors duration-300;
                    &.active{
                        @apply bg-purple-m-main text-white;
                    }
                    > .content{
                        @apply flex flex-col;
                        > .name{
                            @apply font-bold;
                        }
                        > .value{
                            // limit to 2 lines
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2; 
                            -webkit-box-orient: vertical;
                        }
                    }
                    > .actions{
                        @apply invisible;
                    }
                    &:hover{
                        @apply opacity-80;
                        > .actions{
                            @apply visible;
                        }
                    }
                }
            }
        }
    }
</style>
