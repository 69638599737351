<template>
    <div class="bar-chart-item-wrapper">
        <!-- content -->
        <div class="bar-chart-item">
            <div class="label">
                {{ (value.name || value.code).toLowerCase() }}
            </div>
            <div class="progress-box">
                <div class="progress-value" :style="progressValue"></div>
            </div>
            <div class="value">{{ value.weight | numeral('0.0%') }}</div>
        </div>
        <!-- tooltip -->
        <div class="tooltip">
            {{ value.name || value.code }}
        </div>
    </div>
</template>
<script>
    export default {
        props: ['value'],
        data(){
            return {
                hovering: false
            }
        },
        computed: {
            progressValue() {
                let value = (this.value.weight || 0) * 100;
                return {
                    width: `${value}%`
                }
            }
        }
    };
</script>


<style lang="scss" scoped>
    .bar-chart-item-wrapper {
        @apply relative cursor-pointer;

        &:hover{
            > .tooltip{
                @apply visible;
            }
        }
        
        .bar-chart-item{
            
            @apply flex items-center;
            
            .label {
                @apply relative w-48 pr-2;
                @apply text-sm text-left font-bold capitalize truncate;
                @apply flex-grow-0 flex-shrink-0;
                min-width: 200px;
                max-width: 200px;
            }

            .progress-box {
                @apply h-4 w-full relative;
                @apply flex-grow flex-shrink;
                background-color: #D8D8D8;

                .progress-value {
                    @apply absolute h-4 left-0;
                    background-color: #70F880;
                }
            }

            .value {
                @apply text-sm text-black text-right font-bold pl-2;
                @apply flex-grow-0 flex-shrink-0;
                width: 50px;
            }
        }

        > .tooltip{
            @apply absolute invisible;
            @apply px-3 py-1 rounded-md;
            @apply text-white text-sm font-bold;
            background-color: black;
            top: -30px;
        }

    }
</style>