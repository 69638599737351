<template>
    <base-modal 
        :title="computedTitle" 
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="container">

            <div class="content">
                <div
                    class="empty-state" 
                    v-if="!reportUserId || !networksVal"
                >
                    Unable to retrieve report user
                </div>
                <div 
                    class="list"
                    :key="key"
                    v-else
                >
                    <div
                        class="item"
                        @click="toggle(network)"
                        v-for="(network, index) in networkOptions" 
                        :key="`network-option-${index}`"
                    >
                        <input
                            class="creatorsaurus-checkbox" 
                            type="checkbox" 
                            :checked="isSelected(network)"
                        />
                        <base-icon :name="network.name"/>
                        <div>{{network.display_name}}</div>
                    </div>
                </div>
            </div>
            
            <div class="footer">
                <base-button 
                    label="Close"  
                    @action="$emit('close')"
                    :disabled="loading"
                    type="secondary" 
                    bold
                    size="md" 
                />
                <base-button 
                    label="Save"  
                    @action="submit()" 
                    :disabled="loading"
                    bold
                    size="md" 
                />
            </div>

        </div>
    </base-modal>
</template>

<script>
    import { isValidArray, isValidObject, notifyCatchError } from '../../common';
    const networkOptions = [
        { id: 2, name: 'instagram', display_name: 'Instagram' },
        { id: 14, name: 'tiktok', display_name: 'TikTok' },
        { id: 3, name: 'youtube', display_name: 'Youtube' }
    ];
    export default {
        name: 'NetworksModal',
        props: ['visible', 'user'],
        data() {
            return {
                networksVal: [],
                loading: false,
                key: false,
                isValidArray,
                isValidObject,
                networkOptions
            }
        },
        watch: {
            visible(val, old){
                if(val == true){
                    this.networksVal = (isValidObject(this.user) && isValidObject(this.user.report_user) && isValidArray(this.user.report_user.networks)) ? this.user.report_user.networks : [];
                    this.key = !this.key;
                }
            }
        },
        methods: {
            isSelected(network){
                return this.networksVal.includes(network.id);
            },
            toggle(network){
                if(this.isSelected(network)){
                    return this.networksVal = this.networksVal.filter(e => e !== network.id);
                }
                this.networksVal.push(network.id);
            },
            submit(){
                this.loading = true;
                this.$http.put(`/api/report-user/${this.reportUserId}`, {
                    networks: this.networksVal 
                }).then(({ data }) => {
                    if(isValidObject(data)){
                        this.$notify({ title: 'Success', text: `Networks saved successfully`, type: 'success' });
                        this.$emit('reload');
                        this.$emit('close');
                    }
                }).catch(err => {
                    notifyCatchError(err);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        computed: {
            computedTitle(){
                if(isValidObject(this.user)){
                    return `Networks for ${this.user.first_name} ${this.user.last_name}` 
                }
                return `Networks`
            },
            reportUserId(){
                if(isValidObject(this.user) && isValidObject(this.user.report_user) && this.user.report_user.id){
                    return this.user.report_user.id;
                }
                return null;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col;

        .content{
            @apply flex flex-col;
            .list{
                @apply flex flex-col gap-y-3;
                .item{
                    @apply flex gap-x-2 items-center p-2 rounded-md;
                    @apply cursor-pointer transition-colors duration-200;
                    &:hover{
                        @apply bg-gray-200;
                    }
                }
            }
        }

        > .footer{
            @apply flex justify-between;
        }
    }
</style>
