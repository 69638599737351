<template>
    <base-modal 
        title="Custom Fields" 
        :visible="visible"
        @close="close()"
        styles="max-height: 90vh; overflow-y: auto; min-width: 80vw; width: 80vw;"
    >   
        <!-- validate that there is a report user id and selected additional informations -->
        <div v-if="!reportUserId">
            Unable to get report user ID.
        </div>
        <!-- data is ready to render -->
        <div 
            class="additional-info" 
            v-else
        >
            <!-- header -->
            <div class="header">
                <div class="title">Select the creator's custom fields you want for this report</div>
                <div class="actions">
                    <base-button
                        label="Add Info"
                        bold
                        icon="plus"
                        :disabled="loading"
                        @action="createAdditionalInfoModalVisible = true"
                    />
                </div>
            </div>

            <!-- dropdown -->
            <div class="filters">
                <div class="filter">
                    <custom-select
                        placeholder="All projects"
                        :options="projects"
                        :disabled="loading"
                        v-model="selectedProject"
                        allow-null
                        null-label="All projects"
                        :key="`projects-dropdown-${projectsKey}`"
                    />
                </div>
                <div class="filter">
                    <custom-select
                        :placeholder="`All reports ${isValidObject(this.selectedProject) ? `within ${selectedProject.name}` : ``}`"
                        :null-label="`All reports ${isValidObject(this.selectedProject) ? `within ${selectedProject.name}` : ``}`"
                        :options="reports"
                        :disabled="loading"
                        v-model="selectedReport"
                        allow-null
                        :key="`reports-dropdown-${reportsKey}`"
                    />
                </div>
            </div>

            
            <!-- content -->
            <div class="content">
                
                <div class="section">
                    <div class="section-title">This report's additional info</div>
                    <base-icon v-if="loading" name="loader"/>
                    <template v-else>
                        <div class="empty-state" v-if="!isValidArray(filteredAdditionalInfo)">
                            No additional info
                        </div>
                        <div class="info-list" v-else>
                            <custom-fields-modal-item
                                v-for="(info, index) in filteredAdditionalInfo"
                                :key="`info-${index}`"
                                :info="info"
                                :is-selected="isSelected(info)"
                                @toggle-info="toggleInfo(info)"
                                @open-create-additional-info-modal="openCreateAdditionalInfoModal(info)"
                                @delete-additional-info-intent="deleteAdditionalInfoIntent(info.id)"
                            />
                        </div>
                    </template>
                </div>
              
                <div class="section">
                    <div class="section-title">Other additional info</div>
                    <base-icon v-if="loading" name="loader"/>
                    <template v-else>
                        <div class="empty-state" v-if="!isValidArray(otherAdditionalInfo)">
                            No other additional info
                        </div>
                        <div class="info-list" v-else>
                            <custom-fields-modal-item
                                v-for="(info, index) in otherAdditionalInfo"
                                :key="`info-${index}`"
                                :info="info"
                                :is-selected="isSelected(info)"
                                @toggle-info="toggleInfo(info)"
                                @open-create-additional-info-modal="openCreateAdditionalInfoModal(info)"
                                @delete-additional-info-intent="deleteAdditionalInfoIntent(info.id)"
                            />
                        </div>       
                    </template>             
                </div>

            </div>

            <div class="footer">
                <base-button
                    label="Save"
                    bold
                    :disabled="loading"
                    @action="submit()"
                />
            </div>

            <create-additional-info-modal
                :visible="createAdditionalInfoModalVisible"
                :user-id="user.id"
                :current-additional-info="targetAdditionalInfo"
                :report-user-id="user.report_user.id"
                @reload="reload()"
                @close="closeCreateAdditionalInfoModal()"
            />

        </div>
    </base-modal>
</template>

<script>
    import { notifyCatchError } from '@/common';
    import CreateAdditionalInfoModal from './CreateAdditionalInfoModal.vue'
    import { isValidArray, isValidObject } from '../../common';
    import CustomFieldsModalItem from './CustomFieldsModalItem.vue';
    export default {
        components: { 
            CreateAdditionalInfoModal,
            CustomFieldsModalItem 
        },
        name: 'CustomFieldsModal',
        props: ['visible', 'user'],
        watch: {
            visible(val){
                if(val == true){
                    // set report id
                    if(this.user && this.user.report_user && this.user.report_user.id){
                        this.reportUserId = this.user.report_user.id;
                        // will fetch and set "reportUserAdditionalInfos" & "allCreatorAdditionalInfos" at the
                        // same time, this is needed so the selected and available infos are consistent.
                        this.reload();
                    }
                }
            },
            // If the selected project is changed and it does not match 
            // with the current selceted report, the selectedReport will be changed
            // to null
            selectedProject(val, old){
                const val_id = val && val.id ? val.id : null;
                const old_id = old && old.id ? old.id : null;
                if(val_id !== old_id){
                    this.selectedReport = null; 
                    this.reportsKey = !this.reportsKey;
                }
                return;
            },
            // If a report is selected and NO project is selected
            // then find the report's project and auto select it 
            selectedReport(val, old){
                if(val && val.id && !this.selectedProject){
                    let foundProject = null;
                    for (const project of this.projects) {
                        for (const report of project.reports) {
                            if(report.id == val.id){
                                foundProject = project;
                            }
                        }
                    }
                    if(foundProject){
                        this.selectedProject = foundProject;
                        this.projectsKey = !this.projectsKey;
                        this.selectedReport = val;
                        this.reportsKey = !this.reportsKey;
                    }
                }
            }
        },
        data(){
            return {
                reportUserId: null,
                
                allCreatorAdditionalInfos: [],
                reportUserAdditionalInfos: [],
                loading: false,

                selectedProject: null,
                selectedReport: null,
                
                // used to create and edit an additional info
                createAdditionalInfoModalVisible: false,
                targetAdditionalInfo: null,

                projectsKey: false,
                reportsKey: false,

                isValidArray,
                isValidObject
            }
        },
        computed: {
            // Filter additional info so we only show the additional informations that are currently showing on the report
            // all other additional informations that dont go through this filter will be shown on the "Other" section
            filteredAdditionalInfo(){
                if(this.allCreatorAdditionalInfos && this.reportUserId){

                    return this.allCreatorAdditionalInfos.filter(e => {
                        
                        // filter by selected project or report

                        let passes = true;

                        if(isValidObject(this.selectedProject)){
                            const found = e.projects.find(p => p.id == this.selectedProject.id);
                            if(!found){
                                passes = false;
                            }
                        }
                        
                        if(isValidObject(this.selectedReport)){
                            let _found = false;
                            for (const project of e.projects) {
                                for (const report of project.reports) {
                                    if(report.id == this.selectedReport.id){
                                        _found = true;
                                    }
                                }
                            }
                            if(!_found){
                                passes = false;
                            }
                        }

                        return passes;

                    }).filter(element => {
                        if(isValidArray(element.report_user_additional_info)){
                            const found = element.report_user_additional_info.find(e => {
                                // Here we might also want to filter depending on if they are selected or not,
                                // by checking: "!e.deleted_at" however we would have to make sure we are also 
                                // fetching soft deleted records.
                                //
                                // We might also want to filter depending on if the additional information is 
                                // exclusive or not by checking: "e.is_exclusive"
                                //
                                // For now, we are only checking whether there is a relationship between the
                                // additional info and the report user, by checking the this.reportUserId. This might
                                // not bring soft deleted records which means that if an additional field gets 
                                // deselected, it will appear on the "Other" section so just keep that in mind. 
                                return e.report_user_id == this.reportUserId;
                            });
                            if(found) return true;
                        }
                        return false;

                    });
                }
                return [];
            },

            // Returns an array of the excluded elements in the above "filteredAdditionalInfo" function.
            otherAdditionalInfo(){
                if(this.allCreatorAdditionalInfos){
                    return this.allCreatorAdditionalInfos.filter(e => {
                        
                        // filter by selected project or report

                        let passes = true;

                        if(isValidObject(this.selectedProject)){
                            const found = e.projects.find(p => p.id == this.selectedProject.id);
                            if(!found){
                                passes = false;
                            }
                        }
                        
                        if(isValidObject(this.selectedReport)){
                            let _found = false;
                            for (const project of e.projects) {
                                for (const report of project.reports) {
                                    if(report.id == this.selectedReport.id){
                                        _found = true;
                                    }
                                }
                            }
                            if(!_found){
                                passes = false;
                            }
                        }

                        return passes;
                        
                    }).filter(element => {
                        const found = this.filteredAdditionalInfo.find(e => e.id == element.id);
                        if(found){
                            return false
                        }else{
                            return true;
                        }
                    });
                }
                return [];
            },

            reports(){
                let reports = [];
                
                // if there is a selected project, then only return reports inside that project
                if(isValidObject(this.selectedProject)){
                    for (const report of this.selectedProject.reports) {
                        reports.push({...report, label: report.name, value: report.id });
                    }
                }else{
                    // if there is NO selected project, then return all reports found
                    for (const project of this.projects) {
                        for (const report of project.reports) {
                            const foundReport = reports.find(e => e.id == report.id);
                            if(!foundReport){
                                reports.push({...report, label: report.name, value: report.id });
                            }
                        }
                    }    
                }

                return reports;
            },

            projects(){
                let projects = [];
                for (const additionalInfo of this.allCreatorAdditionalInfos) {
                    const _projects = additionalInfo.projects;
                    if(isValidArray(_projects)){
                        for (const project of _projects) {
                            const foundProject = projects.find(e => e.id == project.id);
                            if(!foundProject){
                                projects.push({...project, label: project.name, value: project.id }); // label & value properties are required for custom select
                            }else{
                                for (const report of project.reports) {
                                    const foundReport = foundProject.reports.find(e => e.id == report.id);
                                    if(!foundReport){
                                        foundProject.reports.push({...report, label: report.name, value: report.id }) // label & value properties are required for custom select
                                    }
                                } 
                            }
                        }
                    }
                }
                return projects;
            }

        },
        methods: {

            submit(){
                this.loading = true;
                this.$http.put(`/report-user-additional-info/${this.reportUserId}`, {
                    additional_info: this.reportUserAdditionalInfos
                }).then(({ data }) => {
                    this.$notify({ title: 'Info saved successfully', text: '', type: 'success' });
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                    console.log('submit error', err);
                }).finally(() => {
                    this.loading = false;
                });
                return;
            },

            toggleInfo(info){
                if(this.isSelected(info)){
                    this.reportUserAdditionalInfos = this.reportUserAdditionalInfos.filter(element => element.id !== info.id);
                }else{
                    this.reportUserAdditionalInfos.push(info);
                }
            },

            isSelected(info){
                const found = this.reportUserAdditionalInfos.find(element => element.id == info.id);
                if(found){
                    return true;
                }
                return false;
            },

            // will get all of the report_user additional infos and then it will get all of the user's additional infos. 
            reload(){
                this.loading = true;
                this.$http.get(`/user-additional-info/report-user/${this.reportUserId}?type=report_user`).then(({ data }) => {                    
                    this.reportUserAdditionalInfos = data;
                    this.getAllCreatorAdditionalInfos();
                }).catch(err => {
                    console.log('getReportUserAdditionalInfos error', err);
                }).finally(() => {
                    this.loading = false;
                });
            },

            // given a report user ID, gets all of that report_user's creator additional infos
            getAllCreatorAdditionalInfos(){
                this.loading = true;
                this.$http.get(`/user-additional-info/report-user/${this.reportUserId}?type=user`).then(({ data }) => {
                    this.allCreatorAdditionalInfos = data;
                }).catch(err => {
                    console.log('getAllCreatorAdditionalInfos error', err);
                }).finally(() => {
                    this.loading = false;
                });
                return;
            },

            deleteAdditionalInfoIntent(id){
                this.$swal.fire({
                    title: 'Are you sure you want to delete this information for this creator?',
                    text: `Changes made will also be reflected on this creator's reports`,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.deleteAdditionalInfo(id)
                    }
                })
            },

            async deleteAdditionalInfo(id){
                this.loading = true;
                this.$http.delete(`/user-additional-info/${id}`).then(async (res) => {
                    if(res.data){
                        this.$notify({ title: 'Info deleted successfully', text: '', type: 'success' });
                        this.getAllCreatorAdditionalInfos();
                    }
                }).catch((err) => {
                    console.log('deleteAdditionalInfo error', err)
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false
                })
            },
            
            openCreateAdditionalInfoModal(info){
                this.targetAdditionalInfo = info;
                this.createAdditionalInfoModalVisible = true;
            },

            closeCreateAdditionalInfoModal(){
                this.targetAdditionalInfo = null;
                this.createAdditionalInfoModalVisible = false;
            },

            close(){
                this.reportUserId = null;
                this.allCreatorAdditionalInfos = [];
                this.reportUserAdditionalInfos = [];
                this.createAdditionalInfoModalVisible = false;
                this.targetAdditionalInfo = null;
                this.loading = false;
                this.selectedProject = null;
                this.selectedReport = null;
                this.projectsKey = false;
                this.reportsKey = false;
                this.$emit('close');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .additional-info{
        > .header{
            @apply w-full mb-4 flex justify-between items-center;
            > .title{
                @apply font-bold text-h5;
            }
        }
        > .filters{
            @apply w-full mb-8 flex gap-x-4;
            > .filter{
                @apply;
                width: 350px;
            }
        }
        > .content{
            @apply mt-8 mb-10 flex flex-col items-start gap-y-12;
            .section{
                @apply flex flex-col items-start gap-y-6;
                .section-title{
                    @apply font-bold pb-2 border-green-m-main border-b-2;
                }
                .empty-state{
                    @apply;
                }
                .info-list{
                    @apply flex flex-wrap gap-3;
                }
            }
        }
        > .footer{
            @apply flex justify-end;
        }
    }
</style>
