<template>
    <main class="report-settings" tabindex="0">

        <div class="header">

            <div class="title-container">
                <div class="icon">
                    <base-icon class="text-green-m-main" name="layers" :size="6" />
                </div>

                <div class="title">{{ report.guid ? 'Edit' : 'Create' }} Report</div>
            </div>
            
            <div class="type-updated-at" v-if="report">
                <div class="type" v-if="report.is_default">This is a {{ report.type == 1 ? 'users default' : 'deliverables' }} report</div>
                <div class="updated-at">Last updated on <span>{{ lastUpdated }}</span></div>
            </div>

            <div class="actions">
                <base-button label="View Report" size="md" class="font-bold" theme="dark" @action="$router.push(`/reports/${report.guid}`)"/>
            </div>
        </div>

        <div class="content-pane">
            
            <!-- general -->
            <div class="general">
                <div class="general-grid">
                    <!--<base-button label="Save" @action="saveSettings" id="save-report-btn" display="inline-block"/>-->
                    
                    <!-- project name -->
                    <div class="project-container" v-if="project && project.id">
                        <label>Project</label>
                        <div class="name-container">
                            <div class="name">
                                {{ project.name }}
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="label">Report Title</div>
                        <FormulateInput type="text" placeholder="An Awesome Report" v-model="report.name"/>
                    </div>

                    <div class="field">
                        <div class="label">Report Type</div>
                        <div class="create" v-if="!report.id && !loading">
                            <div class="radio-container">
                                <input type="radio" v-model="report.type" :value="1">
                                <p :class="{'opacity-50' : isContentReport}">Creator report with audience detail</p>
                            </div>
                            <div class="radio-container">
                                <input type="radio" v-model="report.type" :value="2">
                                <p :class="{'opacity-50' : isContentReport}">Collection of content</p>
                            </div>
                        </div>
                        <custom-select v-else :key="typeKey" placeholder="Report type" disabled v-model="reportType" :options="[reportType]"/>
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Description</div>
                        <vue-editor v-model="report.description" placeholder="Description" :editorToolbar="$customToolbar()"/>
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Tags</div>
                        <custom-pills
                            class="w-full"
                            :key="tagsKey"
                            placeholder="Select the report tag(s)"
                            :options="tags"
                            allow-create
                            search
                            create-label="Create new tag"
                            model-name="Tag"
                            :create-route="`/api/tags`"
                            :create-body="{type: 'reports'}"
                            :multiple="true"
                            v-model="selectedTags"
                            @finish-create="getTags"
                        />
                    </div>
                    
                    <!-- settings -->
                    <div class="settings-header">
                        <div class="title">Settings</div>
                        <div class="actions">
                            <base-button 
                                :icon-size="3" 
                                :secondary-icon="!settingsCollapsed ? 'chevron-up' : 'chevron-down'" 
                                size="md" 
                                type="label" 
                                bold 
                                @action="settingsCollapsed = !settingsCollapsed" 
                                :label="settingsCollapsed ? 'Show settings' : 'Hide settings'"
                            />
                        </div>
                    </div>

                    <div class="settings-content" :class="{'collapsed': settingsCollapsed}">
                        
                        <!-- brand report settings -->
                        <div class="brand-report-settings" v-if="report.type === 3">
                            <div class="field">
                                <img v-if="uploadingFile" class="loader" src="../../assets/images/spinner.gif" />
                                <img :src="brandLogoUrl" class="logo" @click="$refs.file.click()"/>
                                <input class="invisible-input" type="file" ref="file" accept="image/*" :disabled="uploadingFile" @change="uploadBrandLogo">
                            </div>
                            <div class="field">
                                <div class="label">Brand name</div>
                                <FormulateInput type="text" placeholder="Brand name" v-model="report.brand.name"/>
                            </div>
                            <div class="field" :key="`${colorsKey}_bg`">
                                <div class="label">Background color</div>
                                <color-picker v-model="report.brand.bg_color"/>
                            </div>

                            <div class="field" :key="`${colorsKey}_emp`">
                                <div class="label">Emphasis color</div>
                                <color-picker v-model="report.brand.emphasis_color"/>
                            </div>
                        </div>

                        <!-- content or creator report settings -->
                        <div class="general-settings" v-if="isCreatorReport || isContentReport">
                            <!-- <div class="text-h6 font-bold">Template</div>
                            <div class="grid grid-cols-2 gap-8">
                                <div class="w-full border px-4 py-3 rounded">
                                    <div class="flex items-center h-5 gap-2">
                                        <FormulateInput @change="toggleOptions" type="checkbox" class="cursor-pointer" v-model="settings_data.platforms" label="View content as platforms template"/>
                                    </div>
                                </div>
                            </div> -->

                            <div class="label">General settings</div>
                            <div class="field-grid">
                                <div class="wrapper">
                                    <div class="input-container" v-if="isCreatorReport">
                                        <FormulateInput 
                                            label="Show users that don't have audience data" 
                                            @change="toggleOptions" 
                                            type="checkbox" 
                                            class="cursor-pointer" 
                                            v-model="settings_data.preview"
                                        />
                                    </div>
                                    <div class="input-container" v-if="isCreatorReport || isContentReport">
                                        <FormulateInput 
                                            label="Show organic + boosted stat breakdown and post ads" 
                                            @change="toggleOptions"
                                            v-model="settings_data.show_boosted_data" 
                                            type="checkbox" 
                                            class="cursor-pointer" 
                                        />
                                    </div>
                                    <div class="input-container" v-if="isContentReport">
                                        <FormulateInput 
                                            label="Hide the social media breakdown at the top of the report" 
                                            @change="toggleOptions" 
                                            type="checkbox" 
                                            class="cursor-pointer" 
                                            v-model="settings_data.hide_resume"
                                        />
                                    </div>
                                    <div class="input-container" v-if="isContentReport">
                                        <FormulateInput 
                                            label="Hide stats on each individual post" 
                                            @change="toggleOptions" 
                                            type="checkbox" 
                                            class="cursor-pointer" 
                                            v-model="settings_data.hide_stats"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="post-limit-settings" v-if="isCreatorReport">
                            <div class="label">Posts Limit</div>
                            <div class="field-grid">
                                <div class="wrapper-alt">
                                    <div class="">
                                        <custom-select placeholder="Posts Limit" :key="postsLimitKey" v-model="settings_data.posts_limit" :options="posts_limits"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- creator report settings (feedback) -->
                        <div class="creator-report-settings" v-if="isCreatorReport">
                            <div class="label">Feedback</div>
                            <div class="feedback-grid">
                                <div class="fields">
                                    <div class="input-container">
                                        <FormulateInput label="Viewer can leave feedback" @change="toggleOptions" type="checkbox" class="cursor-pointer" v-model="settings_data.allow_feedback"/>
                                    </div>
                                    <div class="checkbox-container" v-if="settings_data.allow_feedback">
                                        <FormulateInput v-model="feedback.active" :label="kebabToTitle(feedback.label)" type="checkbox" :key="'fb_id_' + feedback.id" v-for="feedback in settings_data.feedback"/>
                                    </div>
                                </div>
                                <div class="allow-feedback-fields" v-if="settings_data.allow_feedback">
                                    <div class="label">Add Custom Feedback</div>
                                    <FormulateInput
                                        label="Field"
                                        type="text"
                                        placeholder="Enter"
                                        class="w-full"
                                        v-model="newFeedbackOptionField"
                                    />
                                    <custom-select
                                        label="Type"
                                        placeholder="Select"
                                        :options="feedbackOptionTypes"
                                        v-model="newFeedbackOptionType"
                                        :key="newFeedbackOptionTypeKey"
                                        class="w-full"
                                    />

                                    <div class="actions">
                                        <base-button @action="cancelNewFeedbackOption" type="label" size="sm" label="Clear"/>
                                        <base-button @action="addNewFeedbackOption" type="label" size="sm" label="Add Option" :disabled="!newFeedbackOptionField || !newFeedbackOptionType"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- audience settings -->
                        <!-- <div class="audience-settings" v-if="report.id && isCreatorReport">
                            <div class="label">Set the data you want to show</div>
                            <div class="item" v-for="(settings, s_key) in reportSettings" :key="s_key">
                                <div class="item-grid" :class="{'hidden' : s_key === 'audience_likers'}" v-if="Object.keys(settings).length">
                                    <div class="item-container" :class="{'row-span-2': setting_index === 'audience_geo' && checkOptions(op), 'row-span-5': setting_index === 'audience' && checkOptions(op)}" v-for="(op, setting_index) in settings" :key="setting_index">
                                        <div class="input-container" v-if="typeof op === 'object' && op !== null">
                                            <FormulateInput @change="toggleSettings(s_key, setting_index)" type="checkbox" v-model="sections[s_key][setting_index]" :label="kebabToTitle(setting_index)"/>
                                            <template v-if="checkOptions(op)">
                                                <FormulateInput v-for="(deep_op, deep_index) in op" :key="deep_index" class="flex ml-7" @change="toggleOption" type="checkbox" v-model="settings[setting_index][deep_index]" :label="kebabToTitle(deep_index).replace('Audience ', '')"/>
                                            </template>
                                        </div>
                                        <FormulateInput class="relative flex items-start" v-else type="checkbox" v-model="settings[setting_index]" :label="kebabToTitle(setting_index)"/>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>

            <!-- link a post advice -->
            <div class="link-a-post-advice" v-if="isContentReport && loaded && isDefaultReport">
                <div>In order to add content to this report you should link a post to a deliverable.</div>
                <base-button
                    v-tooltip="{ content: `You can not add a content because this is a default deliverable report`, placement: 'left-start', classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                    size="md" display="inline-block" disabled label="Add Content"
                />
            </div>
            
            <!-- add content section -->
            <div class="add-content-section" v-if="isContentReport && loaded && !isDefaultReport">
                <div class="header">
                    Add content to your report

                    <div class="actions">
                        <!-- <base-button type="secondary" :border="false" display="inline-block" size="sm" @action="post_key = !post_key" label="Refresh"/> -->
                        <base-button 
                            label="Add Manual Content" 
                            size="auto" 
                            @action="closeAddEditCreatorModal(true); openManualPost = true" 
                            display="inline-block" 
                            :disabled="savingPost"
                        />
                        <!-- bookmarklet: change ALL "handleLink" for "savePost" -->
                        <base-button 
                            :label="preparingPost ? 'Saving...' : 'Add Content'" 
                            size="md" 
                            @action="savePost" 
                            display="inline-block" 
                            :disabled="preparingPost || newPost.link === ''"
                        />
                        <base-button 
                            label="IG Hashtag Search" 
                            size="lg" 
                            @action="instagramHashtagSearchModalVisible = true" 
                            display="inline-block" 
                        />
                    </div>
                </div>

                <div class="content">
                    <div class="label">Content Link</div>
                    <FormulateInput
                        type="text"
                        placeholder="https://"
                        v-model="newPost.link"
                        help-position="after"
                        :input-class="['not-uppercased-placeholder']"
                        :help="invalidLink ? 'Please, check the url and try again' : null"
                        help-class="font-bold text-red-600"
                    />
                </div>

                <div class="error" v-if="newPostError !== ''">{{ newPostError }}</div>
            </div>
            
            <!-- report groups -->
            <div class="report-groups-container" v-if="isContentReport">
                <report-groups 
                    as-circles 
                    :key="groupsKey" 
                    :refreshed="report.settings.skip || groupsRefreshed" 
                    @refresh="refreshGroups" 
                    :allow-edit="true" 
                    :clean="cleanGroups" 
                    @save="saveGroups" 
                    :networks="networksValues" 
                    :initial-groups="report.settings.groups" 
                    :posts="report.posts" 
                    with-title
                />
            </div>
            
            <!-- table header for creators or brand report -->
            <div class="creator-brand-table-header" v-if="report.id">

                <div class="title-container">
                    {{ isCreatorReport || isBrandReport ? 'Creators' : 'Content' }} Assigned
                    <span v-if="isCreatorReport || isBrandReport">({{ report.influencers.length }})</span>
                    <span v-if="isContentReport">({{ report.posts.length }})</span>
                </div>

                <div class="actions">
                    <base-button 
                        label="Save changes"
                        v-if="!isCreatorReport && !isBrandReport && changes.length" 
                        size="lg" 
                        display="inline-block" 
                        @action="changes = []" 
                    />
                    <base-button 
                        label="Add Creator"
                        v-if="report && !report.is_default && report.type !== 2" 
                        display="inline-block"  
                        @action="addEditCreatorModalVisible = true" 
                        :disabled="savingCreator"
                    />
                    <refresh-report 
                        v-if="!isContentReport || !changes.length" 
                        :report="report" 
                        :user="me" 
                        allow-refresh 
                        :in-report="false" 
                        @show-changes="showChanges" 
                        @reload="getReport(false)"
                    />
                    <base-button 
                        label="Modify Columns"
                        v-if="!isCreatorReport && !isBrandReport" 
                        size="lg" 
                        display="inline-block" 
                        @action="modifyColumns = true" 
                    />
                </div>
            </div>
            
            <!-- creators table for creator or brand report -->
            <base-table
                v-if="(isCreatorReport || isBrandReport) && report.id"
                class="mb-24 px-12"
                :key="user_key"
                :columns="usersColumns"
                :rowActions="[
                    {
                        label: 'Reload',
                        icon: 'pencil-alt',
                        event: 'reload',
                        if: (creator) => {
                            return creator.pending;
                        },
                        handler: (creator) => {
                            reloadCreator(creator)
                        }
                    },
                    ...(isDefaultReport ? [] :
                    {
                        label: 'Edit description',
                        icon: 'pencil-alt',
                        event:'edit',
                        handler: (creator) => {
                            editCreator(creator)
                        }
                    }),
                    {
                        label: 'Custom fields',
                        icon: 'pencil-alt',
                        event: 'edit-creator-custom-fields',
                        handler: (creator) => {
                            editCreatorCustomFields(creator)
                        }
                    },
                    {
                        label: 'Manage Featured Posts',
                        event: 'manageFeaturedPosts', 
                        handler: (influencer) => {
                            openFeaturedPostsModal(influencer);
                        }
                    },
                    {
                        label: 'Manage Networks',
                        event: 'manageNetworks', 
                        handler: (influencer) => {
                            openNetworksModal(influencer);
                        }
                    },
                    {
                        label: 'View available post data',
                        event: 'viewAudienceData',
                        if: (influencer) => {
                            const { audience_data } = influencer;
                            let hasValidAudienceData = false
                            if(audience_data && Array.isArray(audience_data)){
                                const filtered = audience_data.filter(element => {
                                    return element.payload
                                });
                                if(filtered.length) hasValidAudienceData = true;
                            } 
                            return hasValidAudienceData; 
                        },
                        handler: (influencer) => {
                            viewAudienceData(influencer);
                        }
                    },
                    {
                        label: 'Invite login with Facebook',
                        event: 'inviteInstagram',
                        if: (creator) => {
                            let hasAccount = creator.social_accounts.find(sa => sa.network_id == 2);
                            let hasLogin = creator.social_logins.find(s => s.network_id == 2);
                            return !!hasAccount && !hasLogin && isAdminOrHigher;
                        },
                        handler: (creator) => {
                            sendInvitation(2, creator);
                        }
                    },
                    {
                        label: 'Invite login with Tiktok',
                        event: 'inviteTiktok',
                        if: (creator) => {
                            let hasAccount = creator.social_accounts.find(sa => sa.network_id == 14);
                            let hasLogin = creator.social_logins.find(s => s.network_id == 14);
                            return !!hasAccount && !hasLogin && isAdminOrHigher;
                        },
                        handler: (creator) => {
                            sendInvitation(14, creator);
                        }
                    },
                    {
                        label: 'Add manual Instagram data', // should not be exactly be shown when there is no post data, it should show if the top_posts of the audience data dont sum up to the posts needed
                        event: 'addManualInstagamData',
                        if: (creator) => {
                            let hasAccount = creator.social_accounts.find(sa => sa.network_id == 2);
                            let hasAudienceData = false;
                            if(creator.audience_data && Array.isArray(creator.audience_data)){
                                const igAudienceData = creator.audience_data.find(element => element.network_id == 2);
                                if(igAudienceData && igAudienceData.payload){
                                    hasAudienceData = true;
                                }
                            } 
                            return !!hasAccount && !hasAudienceData; 
                        },
                        handler: (creator) => {
                            addManualAudienceData(2, creator);
                        }
                    },
                    {
                        label: 'Add manual Tiktok data',
                        event: 'addManualTiktokData',
                        if: (creator) => {
                            let hasAccount = creator.social_accounts.find(sa => sa.network_id == 14);
                            let hasAudienceData = false;
                            if(creator.audience_data && Array.isArray(creator.audience_data)){
                                const tiktokAudienceData = creator.audience_data.find(element => element.network_id == 14);
                                if(tiktokAudienceData && tiktokAudienceData.payload){
                                    hasAudienceData = true;
                                }
                            } 
                            return !!hasAccount && !hasAudienceData; 
                        },
                        handler: (creator) => {
                            addManualAudienceData(14, creator);
                        }
                    },
                    {
                        label: 'View Creator',
                        icon: 'eye',
                        event: 'viewCreator',
                        handler: (creator) => {
                            $router.push(`/creator/${creator.id}`)
                        }
                    },
                    ...(isDefaultReport ? [] :
                    {
                        label: 'Delete',
                        icon: 'trash',
                        theme: 'cancel',
                        event: 'delete',
                        handler: (creator) => {
                            removeCreator(creator)
                        }
                    })
                ]"
                :is-draggable="!!report.id"
                @update-values="updateValues"
                @set-accounts="setAccounts"
                :data="influencers"
                :loading="loading || removingCreator || addingCreatorToReport || savingCreator || reloadingCreator"
                emptyText="No creator added yet!"
                :search="false"
                overflow
                internal-pagination
                :page="itemsPage"
                :per-page="5"
                :total="report.influencers ? report.influencers.length : 0"
                allow-show-all
                @changePage="changePage"
                searchbarStyles="width: 400px;"
                actions-position="top"
            />

            <!-- brand report -->
            <div class="brand-report-add-content-section" v-if="isBrandReport">
                <div class="header">
                    Add content to your report

                    <div class="actions">
                        <!-- <base-button type="secondary" :border="false" display="inline-block" size="sm" @action="post_key = !post_key" label="Refresh"/> -->
                        <!-- bookmarklet: change ALL "handleLink" for "savePost" -->
                        <base-button size="md" @action="savePost" display="inline-block" :disabled="preparingPost || newPost.link === ''" :label="preparingPost ? 'Saving...' : 'Add Content'"/>
                    </div>
                </div>

                <div class="content">
                    <div class="label">Content Link</div>
                    <FormulateInput
                        type="text"
                        placeholder="https://"
                        v-model="newPost.link"
                    />
                </div>

                <div class="error" v-if="newPostError !== ''">{{ newPostError }}</div>
            </div>

            <!-- brand report posts table header -->
            <div class="brand-report-posts-table-header" v-if="report.id && isBrandReport">
                <div class="title-container">
                    Content Assigned
                    <span>({{ report.posts ? report.posts.length : 0}})</span>
                </div>

                <div class="actions" v-if="!isCreatorReport && !isBrandReport">
                    <base-button size="lg" display="inline-block" @action="modifyColumns = true" label="Modify Columns"/>
                </div>
            </div>
            
            <!-- posts table for content or brand report -->
            <base-table
                v-if="(isContentReport || isBrandReport) && report.id"
                class="px-12"
                :columns="postsColumns"
                :rowActions="[
                    {
                        label: 'Edit',
                        icon: 'edit',
                        event: 'edit',
                        handler: (post) => {
                            editPost(post)
                        }
                    },
                    {
                        label: 'Refresh',
                        icon: 'refresh',
                        event: 'refresh',
                        handler: (post) => {
                            refreshPost(post)
                        }
                    },
                    {
                        label: 'Remove',
                        icon: 'trash',
                        theme: 'cancel',
                        event: 'delete',
                        handler: (post) => {
                            removePostIntent(post)
                        }
                    },
                    {
                        label: 'Manage ads',
                        if: (post) => post.network_id == 2,
                        icon: 'edit',
                        event: 'ads',
                        handler: (post) => openPostAdsModal(post)
                    }
                ]"
                actions-position="top"
                :data="computedPosts"
                :loading="loading || savingPost || preparingPost || removingPost"
                emptyText="No content added yet!"
                :search="false"
                overflow
                container-styles="overflow-x: auto;"
                internal-pagination
                :page="itemsPage"
                :per-page="5"
                :total="report.posts ? report.posts.length : 0"
                allow-show-all
                @changePage="changePage"
                searchbarStyles="width: 400px;"
            />

            <div class="sticky border-gray-300 border-t bottom-0 right-0 flex justify-end gap-x-4 px-12 py-10 bg-white w-full z-100">
                <template>
                    <base-button @action="$router.back()" class="bg-tan-m" type="secondary" label="Discard"/>
                    <base-button :disabled="savingCreator || savingPost" @action="saveSettings" :label="report.id ? 'Save' : 'Create Report'"/>
                </template>
            </div>
        </div>

        <!-- MODALS SECTION -->

        <!-- add / edit creator modal -->
        <base-modal
            :title="`${isEditingCreator ? 'Edit' : 'Add' } Creator`"
            @close="closeAddEditCreatorModal()"
            :visible="addEditCreatorModalVisible"
            styles="width: 1900px; min-height: 600px;"
            v-if="(isCreatorReport || isBrandReport) && !isDefaultReport"
        >
            <div class="flex flex-col space-y-3">
                
                <!-- tabs -->
                <div class="flex justify-between" v-if="!isEditingCreator">
                    <tabs :tabs="['search', 'create']" v-model="addCreatorTab" capitalize/>
                </div>

                <!-- search tab -->
                <template v-if="addCreatorTab == 'search' && !isEditingCreator">
                    
                    <!-- search input -->
                    <div class="relative flex w-full">
                        <div class="absolute w-16 h-full flex items-center justify-center pointer-events-none">
                            <base-icon name="search" class="text-purple-m-secondary" />
                        </div>
                        <FormulateInput
                            type="text" 
                            placeholder="Search creator" 
                            v-model="search" 
                            v-debounce:1000="runSearch"
                            outer-class="w-full"
                            element-class="w-full"
                            wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                        />
                        <span 
                            v-if="searching"
                            class="absolute right-5 top-0 bottom-0 flex items-center"
                        >
                            <base-icon name="loader" />
                        </span>
                    </div>
                    
                    <!-- empty state -->
                    <div class="col-span-2" v-if="noResults && !results.length">No results found for this search</div>

                    <div class="col-span-2" v-if="!noResults && !results.length && search.trim() == ''">Search creators</div>

                    <!-- list container -->
                    <div class="field border rounded-md col-span-2 max-h-60 overflow-y-auto" v-if="results.length">
                        <create-report-influencer-list
                            :influencers="results"
                            :select="true"
                            :toggle="true"
                            :selected="[]"
                            :direct="true"
                            :disabled="addingCreatorToReport"
                            @toggle-creator-network="addCreatorNetworkToggle"
                            @direct-select="addCreator"
                        />
                    </div>
                </template>
                
                <!-- create tab -->
                <div v-if="addCreatorTab == 'create' || isEditingCreator">

                    <create-influencer-form
                        v-if="!newCreator"
                        :tags="creatorTags"
                        inline
                        no-tag-required
                        force-prompt
                        force-networks
                        @created-influencer="onCreatedCreator"
                        @cancel-form="addCreatorTab = 'search'"
                        @reload-tags="getCreatorTags"
                    />

                    <div v-if="newCreator" class="mb-4">
                        <div class="mb-4">
                            <div v-if="!isEditingCreator" class="text-h4 font-bold">Creator created successfully</div>
                            <div>Add a description for this creator inside this report.</div>
                        </div>
                        <vue-editor
                            v-model="newCreator.description" 
                            placeholder="Extra information displayed for the creator" 
                            :editorToolbar="$customToolbar()"
                        />
                    </div>

                </div>

            </div>

            <div class="flex justify-between" v-if="isEditingCreator || addCreatorTab == 'create'">
                <base-button
                    v-if="newCreator"
                    label="Cancel"
                    @action="closeAddEditCreatorModal()" 
                    type="secondary"
                />
                <base-button
                    v-if="newCreator"
                    :label="savingCreator ? 'Saving' : (isEditingCreator ? 'Edit Creator' : 'Add Creator')" 
                    :disabled="disabledForm || savingCreator"
                    @action="addNewCreatorToReport" 
                />
            </div>

        </base-modal>

        <custom-fields-modal
            :visible="customFieldsModalVisible"
            :user="customFieldsCreator"
            @refresh="getReport()"
            @close="customFieldsModalVisible = false"
        />


        <base-modal :visible="promptManual" title="Add Manual Content" @close="promptManual = false">
            <template v-if="newPost && newPost.link && newPost.link.includes('tiktok.com')">
                <p>We were unable to get this tiktok post, please make sure that the creator has connected their tiktok account so we can automatically get their profile information and posts.</p>
                <p>You can also add this post manually. Do you want to continue?</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptManual = false" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="promptManual = false;openManualPost = true" size="lg" label="Add post manually"/>
                </div>
            </template>
            <template v-else>
                <p>Right now this content is coming from an unsupported platform or the post is unavailable, but you can add it manually. Do you want to continue?</p>
                <p>You can also invite the user to authenticate with supported platforms to automatically get their profile information and posts.</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptManual = false" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="promptManual = false;openManualPost = true" size="lg" label="Add post manually"/>
                </div>
            </template>
        </base-modal>

        <manual-post 
            :suggested-link="newPost.link" 
            :open="openManualPost" 
            @close="openManualPost = false" 
            @attach-existent="attachExistent" 
            @finish-manual="updateReport"
        />

        <base-modal :visible="modifyColumns" @close="modifyColumns = false" title="Modify Content Columns">
            <div v-for="column in report.settings.columns" :key="column.id">
                <FormulateInput @change="saveReport(false)" type="checkbox" v-model="column.active" :label="column.label" v-if="!hidden_columns.includes(column.id)"/>
            </div>
        </base-modal>

        <base-modal :visible="attachment !== null" :hide-close-button="true" title="Post in deliverable">
            <p class="text-center">This post is already linked to a deliverable. Would you like to use it?</p>

            <div class="w-3/5 flex justify-between mx-auto mt-4">
                <!-- bookmarklet: change ALL "handleLink" for "savePost" -->
                <base-button type="secondary" label="No, continue" @action="savePost('?ignore=true')"/>
                <base-button label="Use the deliverable" size="lg" @action="savePost('?ignore=true&fromDeliverable=true')"/>
            </div>
        </base-modal>

        <update-post 
            :open="editingPost && currentPost.id !== undefined" 
            :current-post="currentPost" 
            @close="cancelEditingPost" 
            @finish-update="finishUpdate"
        />
        
        <post-summary-modal 
            :visible="postSummaryModalVisible" 
            :post="currentPost"
            :report-id="report.id"
            @close="postSummaryModalVisible = false"
            @done="getReport()"
        />

        <update-post
            title="Set missing data for this content"
            subtitle="This content needs attention. Please fill the necessary data in order to show it in the report."
            is-force
            v-for="pendingPost in pendingPosts"
            :key="`${pendingPost.id}_${p_post_key}`"
            :open="p_post_key"
            :current-post="pendingPost"
            @close="removePending(pendingPost.id, true)"
            @finish-update="removePending(pendingPost.id)"
        />

        <base-modal @close="cancelHandle" :visible="requestHandle" title="Insert the handle for the user">
            <p>We are unable to get the username for this content. Please provide the handle so we can get the data.</p>
            
            <FormulateInput
                type="text"
                class="w-full"
                placeholder="User Handle"
                label="User Handle"
                v-model="userHandle"
            />

            <div class="mt-4 flex justify-between">
                <base-button @action="cancelHandle" type="secondary" size="md" label="Cancel"/>
                 <!-- bookmarklet: change ALL "handleLink" for "savePost" -->
                <base-button @action="savePost" label="Continue"/>
            </div>
        </base-modal>

        <fixed-loader :visible="!!savingItemCount.length || addingCreatorToReport || savingCreator || reloadingCreator" :label="loaderLabel"/>

        <send-invite-modal
            @close="cancelInvite"
            :visible="platformIntent.show"
            :user="platformIntent.user"
            :networkLabel="platformIntent.networkLabel"
            :networkId="platformIntent.networkId"
        />

        <base-modal :hide-header="true" persistent :visible="missingMediaModalVisible">
            <div class="flex flex-col gap-4">
                <base-icon class="m-auto mt-6" size="12" name="exclamation-circle"/>
                <p class="text-h4 text-center mt-4 font-bold">We weren't able to get the media for this post.</p>
                <p class="text-center mt-2">FB won't return media if the post contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.</p>
                <p class="text-center mt-2 font-bold">Would you still like to add the post ? The post thumbnail will be shown and you can upload the media manually.</p>

                <div class="flex justify-center w-full gap-16 mt-6">
                    <base-button @action="missingMediaModalVisible = false" type="secondary" size="md" bold label="Cancel"/>
                     <!-- bookmarklet: change ALL "handleLink" for "savePost" -->
                    <base-button @action="savePost('', true)" size="md" label="Add post" bold/>
                </div>
            </div>
        </base-modal>

        <base-modal @close="promptExists = !promptExists" hide-close-button :visible="existingHandle && promptExists" title="Creator handle already exists">
            <div class="flex flex-col gap-4">
                <div v-if="existingHandle !== null" class="flex flex-col gap-2">
                    <p>The username belongs to the following user. Do you want to attach this user instead?</p>
                    <div>
                        <div class="flex gap-2 items-center">
                            <avatar :user="existingHandle"/>
                            <div class="flex flex-col gap-0">
                                <div class="font-semibold text-lg text-black">{{ existingHandle.first_name }} {{ existingHandle.last_name }}</div>
                                <div>{{ existingHandle.email }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-else>This username belongs to an existing user. Do you want to select that user instead?</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptExists = !promptExists" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="acceptExisting" label="Accept"/>
                </div>
            </div>
        </base-modal>
    
        <manual-audience-data-modal 
            :visible="manualAudienceDataModalVisible"
            :report-id="report.id"
            :user="manualAudienceDataModalUser"
            :network-id="manualAudienceDataModalNetworkId"
            @close="manualAudienceDataModalVisible = false; getReport();"
        />

        <!-- :report-id="report.id" -->
        <view-audience-data-modal 
            :visible="viewAudienceDataModalVisible"
            :user="viewAudienceDataModalUser"
            :replaced-posts="report.replaced_posts || []"
            @close="viewAudienceDataModalVisible = false"
        />

        <featured-posts-modal 
            :visible="featuredPostsModalVisible"
            :user="featuredPostsModalUser"
            @close="featuredPostsModalVisible = false"
            @reload="getReport(true, 1000)"
        />

        <networks-modal 
            :visible="networksModalVisible"
            :user="networksModalUser"
            @close="networksModalVisible = false"
            @reload="getReport(true, 1000)"
        />

        <instagram-hashtag-search-modal
            :visible="instagramHashtagSearchModalVisible"
            @reload="getReport()"
            @close="instagramHashtagSearchModalVisible = false"
        />

        <post-ads-modal
            :visible="postAdsModalVisible"
            :value="currentPost.boosted_data"
            :post-id="currentPost.id"
            @reload="buildPayloadAndGetReport()"
            @close="postAdsModalVisible = false"
        />
        
        <!-- bookmarklet: uncomment -->
        <!-- <tiktok-bookmarklet :open="adviseTiktok" @close="adviseTiktok = false" @confirm="savePost"/> -->
    </main>
</template>

<script>
import DefaultBrandLogo from './../../assets/images/default-user.png';

import {customNetwork, NETWORKS, getObjectValue} from '@/common';
import {snakeCaseToTitle, validateUrl, parseUnicodeCharacters} from '@/lib/strings';

import { recommendationsAlert } from '@/lib/alerts';
import CreateReportInfluencerList from '@/components/CreateReportInfluencerList';
import SendInviteModal from '@/components/SendInviteModal.vue';
import ColorPicker from '@/components/ColorPicker';
import NetworkSelect from '@/components/NetworkSelect';
import CreateInfluencerForm from '@/components/CreateInfluencerForm.vue';

import ReportGroups from './ReportGroups';
import ManualPost from './ManualPost/index.vue';
import UpdatePost from './UpdatePost';
import PostSummaryModal from './PostSummaryModal.vue'
import RefreshReport from './RefreshReport';
import ManualAudienceDataModal from './ManualAudienceDataModal';
import ViewAudienceDataModal from './ViewAudienceDataModal';
import FeaturedPostsModal from './FeaturedPostsModal.vue';
import NetworksModal from './NetworksModal.vue';
import InstagramHashtagSearchModal from './InstagramHashtagSearchModal.vue';
import PostAdsModal from './PostAdsModal/index.vue';
import CustomFieldsModal from './CustomFieldsModal.vue';

// bookmarklet: uncomment
// import TiktokBookmarklet from './TiktokBookmarklet';
// import { scrapperFn } from '../../lib/scrapperDom';

import { mapGetters } from 'vuex';
import { isNotNull, isValidArray, isValidObject, notifyCatchError } from '../../common';
import { getBoostedData, isPostPropertyBoosted, generateBreakdown } from '../../lib/fbBoostedData';

export default {
    components: {
        NetworkSelect,
        CreateReportInfluencerList,
        ReportGroups,
        ColorPicker,
        ManualPost,
        UpdatePost,
        PostSummaryModal,
        RefreshReport,
        // bookmarklet: uncomment
        // TiktokBookmarklet,
        SendInviteModal,
        CreateInfluencerForm,
        ManualAudienceDataModal,
        ViewAudienceDataModal,
        FeaturedPostsModal,
        NetworksModal,
        InstagramHashtagSearchModal,
        PostAdsModal,
        CustomFieldsModal
    },

    data() {
        return {
            post_key: false,
            user_key: false,
            groupsKey: false,
            p_post_key: false,
            addEditCreatorModalVisible: false,
            missingMediaModalVisible: false,
            creatorNetworkKey: false,
            addCreatorTab: 'search',
            report: {
                id: null,
                type: 1,
                name: '',
                project_id: null,
                description: '',
                allow_feedback: false,
                is_default: false,
                influencers: [],
                settings: {},
                posts: [],
                brand: {}
            },

            newCreator: null,
            savingCreator: false,

            suggested_instagram: {
                loading: false,
                show: false,
                items: []
            },

            networksValues: [],

            suggested_tiktok: {
                loading: false,
                show: false,
                items: []
            },

            newFeedbackOptionField: '',
            newFeedbackOptionType: null,
            newFeedbackOptionTypeKey: false,
            feedbackOptionTypes: [
                { id: 1, label: 'Rank 1-5' },
                { id: 2, label: 'Free Text' },
            ],

            uploadingFile: false,

            newPost: {
                link: '',
            },

            networkValues: {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: '',
                pinterest_handle: {...customNetwork(4)},
                facebook_handle: {...customNetwork(6)},
                twitter_handle: {...customNetwork(7)},
                linkedin_handle: {...customNetwork(16)},
                custom_handle: {...customNetwork(17)}
            },

            reloadingCreator: false,

            newPostError: '',
            savingPost: false,

            NETWORKS,

            project: {},

            sections: {
                audience_followers: {
                    audience_geo: false,
                    audience: false
                },
                audience_likers: {
                    audience_geo: false,
                    audience: false
                }
            },

            settings_data: {
                allow_feedback: false,
                hide_resume: false,
                hide_stats: false,
                preview: false,
                audience_followers: {
                    audience_geo: false,
                    audience: false
                },
                audience_likers: {
                    audience_geo: false,
                    audience: false
                },
                feedback: [],
                groups: [],
                columns: [],
                platforms: true,
                posts_limit: {id: 9, label: 9},
                loading_type: {id: 'all', label: 'All at once'},
            },

            settings_hidden: [
                'audience_likers',
                'allow_feedback',
                'feedback',
                'ages',
            ],

            hidden_columns: [
                'thumbnail',
                'influencer-name',
                'link'
            ],

            settingsCollapsed: true,
            savingSettings: false,
            modifyColumns: false,

            isEditingCreator: false,

            editedCreator: {},
            
            // custom fields
            customFieldsCreator: null,
            customFieldsModalVisible: false,

            loading: false,
            loaded: false,
            removingCreator: false,
            removingPost: false,

            userFormKey: false,

            cleanGroups: false,
            searching: false,
            noResults: false,
            addingCreatorToReport: false,
            search: '',
            results: [],
            typeKey: false,
            postsLimitKey: false,
            loadingTypeKey: false,
            colorsKey: 0,
            promptManual: false,
            openManualPost: false,
            groupsRefreshed: false,
            
            currentPost: {
                profile_item: {}
            },
            editingPost: false,
            postSummaryModalVisible: false,

            // bookmarklet: uncomment
            // adviseTiktok: false,

            tagsKey: false, 
            tags: [],
            creatorTags: [],
            selectedTags: [],
            preparingPost: false,
            savingItemCount: [],

            attachment: null,
            pendingPosts: [],
            changes: [],

            itemsPage: 1,

            platformIntent: {
                show: false,
                user: null,
                networkId: null,
                networkLabel: null
            },

            userHandle: '',
            requestHandle: false,
            changingSettings: false,

            posts_limits: [
                { id: 3, label: 3}, { id: 4, label: 4}, { id: 5, label: 5},
                { id: 6, label: 6}, { id: 7, label: 7}, { id: 8, label: 8},
                { id: 9, label: 9},
            ],

            loading_types: [
                { id: 'cascade', label: 'Sequentially'},
                { id: 'scroll', label: 'By scrolling'},
                { id: 'all', label: 'All at once'},
            ],

            // manual post replace modal
            manualAudienceDataModalVisible: false,
            manualAudienceDataModalUser: null,
            manualAudienceDataModalNetworkId: null,
            
            // view audience data modal
            viewAudienceDataModalVisible: false,
            viewAudienceDataModalUser: null,

            // featured posts modal
            featuredPostsModalVisible: false,
            featuredPostsModalUser: null,

            // networks modal
            networksModalVisible: false,
            networksModalUser: null,

            // instagram hashtag search
            instagramHashtagSearchModalVisible: false,

            // post ads modal
            postAdsModalVisible: false,
            
            exists: false,
            promptExists: false,
            disabledForm: false,
            existingHandle: false,

            parseUnicodeCharacters
        }
    },

    computed: {
        ...mapGetters(['me', 'isUserAdminOrHigher', 'isAdminOrHigher']),

        lastUpdated() {
            let date = new Date(this.report.updated_at);
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        },

        computedPosts() {
            let posts = JSON.parse(JSON.stringify(this.report.posts || []));

            return posts.map(p => {
                p.hasChanges = this.changes.length > 0;
                if (p.hasChanges) {
                    let change = this.changes.find(c => c.old.id == p.id);
                    if (change) {
                        p.likes = [change.old.likes, change.new.likes];
                        p.comments = [change.old.comments, change.new.comments];
                        p.views = [change.old.views, change.new.views];
                        // p.views = [change.old.views, change.new.views];
                        p.shares = [change.old.shares, change.new.shares];
                        p.saves = [change.old.saves, change.new.saves];
                        p.profile_item.total_followers = [change.old.profile_item.total_followers, change.new.profile_item.total_followers];
                    }
                }
                return p;
            });
        },

        invalidLink() {
            return this.newPost.link.length > 10 && !validateUrl(this.newPost.link);
        },

        loaderLabel() {
            let label = 'content';
            if (this.isCreatorReport) {
                label = 'user';
                return this.reloadingCreator ? 'Getting user' : 'Saving user';
            } else if(this.isBrandReport) {
                label = 'item';
            }

            return `Saving ${this.savingItemCount.length} ${label}${this.savingItemCount.length > 1 ? 's' : ''}`;
        },

        influencers() {
            // for each of the influencers we will set: 
            // (BOOLEAN) influencer.pending
            // (ARRAY|STRING) influencer.platforms
            if(!isValidArray(this.report.influencers)){
                return [];
            }
            return this.report.influencers.map(influencer => {

                influencer.pending = false;

                const toggledNetworks = getObjectValue(influencer, 'report_user.networks', []);

                const isUsingFeaturedPosts = influencer.report_user && isValidArray(influencer.report_user.featured_posts);

                // if they have audience data and there is no payload, they are pending.
                if (isValidArray(influencer.audience_data) && influencer.audience_data.length) {
                    for (let i in influencer.audience_data) {

                        if (!influencer.audience_data[i].payload && !influencer.pending) {
                            influencer.pending = true;
                        }

                        // Don't check top posts if we are showing featured posts
                        if(!isUsingFeaturedPosts){
                            // check if toggled networks top posts are empty
                            if(toggledNetworks.includes(influencer.audience_data[i].network_id)){
                                const top_posts = getObjectValue(influencer.audience_data[i], 'payload.user_profile.top_posts')
                                if(!top_posts || !Array.isArray(top_posts) || !top_posts.length){
                                    influencer.pending = true;
                                }
                            }
                        }

                    }
                } else {
                    // if they dont have audience data, they are pending
                    influencer.pending = true;
                }
                
                // set influencer platforms
                let platforms = isValidArray(influencer.social_accounts) ? influencer.social_accounts.filter(a => NETWORKS[a.network_id]).map(a => NETWORKS[parseInt(a.network_id)].display_name) : [];
                influencer.platforms = platforms.join(', ');
                
                // if influencer is pending, platforms is a string
                if (influencer.pending) {
                    influencer.platforms = 'Queued...';
                }

                return influencer;

            });
        },

        computedNetworks() {
            let networks = {};
            for (let pos in this.NETWORKS) {
                networks[pos] = this.NETWORKS[pos].display_name;
            }
            return networks;
        },

        customComputedNetworks() {
            let networks = {};
            for (let pos in this.computedNetworks) {
                let item = this.computedNetworks[pos];
                if (!['Instagram', 'Tiktok', 'Youtube'].includes(item)) {
                    networks[pos] = item;
                }
            }
            return networks;
        },

        customComputedAvailableNetworks() {
            let networks = [];
            for (let pos in this.customComputedNetworks) {
                let item = this.computedNetworks[pos];
                if (!['Instagram', 'Tiktok', 'Youtube'].includes(item)) {
                    networks.push({
                        id: pos,
                        name: item
                    });
                }
            }
            return networks;
        },

        reportType: {
            get() {
                return {
                    id: this.report.type,
                    label: this.isCreatorReport ? 'Creators' : this.isContentReport ? 'Contents' : 'Brand'
                }
            },
            set() {
            }
        },

        brandLogoUrl() {
            return this.report.brand && this.report.brand.logo ? this.report.brand.logo : DefaultBrandLogo;
        },

        isCreatorReport() {
            return this.report.type === 1;
        },

        isContentReport() {
            return this.report.type === 2;
        },

        isBrandReport() {
            return this.report.type === 3;
        },

        isDefaultReport() {
            return this.report.is_default;
        },

        reportSettings() {
            let options = {};
            let settings = this.settings_data;
            for (let i in settings) {
                if (['feedback', 'groups', 'columns', 'posts_limit', 'loading_type'].includes(i)) {
                    continue;
                }
                if (typeof settings[i] === 'boolean') {
                    options[i] = settings[i];
                    continue;
                }
                let elements = {};
                let audience = {};
                for (let j in settings[i]) {
                    let item = settings[i][j];
                    if (typeof item !== 'object' && j.includes('audience')) {
                        audience[j] = item;
                    } else {
                        elements[j] = item;
                    }
                }

                if (Object.keys(audience).length && audience.constructor === Object) {
                    elements['audience'] = audience;
                }

                options[i] = elements;
            }
            return options;
        },

        usersColumns() {
            return [
                    {
                        name: 'influencer-name',
                        label: 'Creator Name',
                        type: 'avatar-list',
                        value: (element) => {
                            return [element]
                        }
                    },
                    {
                        name: 'total-reach',
                        label: 'Total Reach',
                        type: 'number-format',
                        value: this.creatorReach
                    },
                    {
                        name: 'avg-engagements',
                        label: 'Avg. Engagements',
                        type: 'number-format',
                        value: this.creatorEngagements
                    },
                    {
                        name: 'post-show-type',
                        label: 'Post show type',
                        type: 'post-show-type',
                        value: (element) => {
                            return element.report_user.post_show_type
                        },
                        onUpdateValue: this.onUpdatePostShowType
                    },
                    {
                        name: 'platforms',
                        label: 'Platforms',
                        help: '(Click to toggle visibility on report)',
                        type: 'networks',
                        currentValues: this.networksValues,
                        currentAccounts: (element) => {
                            return element.report_user.networks;
                        },
                        selector: true,
                        value: this.platformsValue
                    }
                ]
        },

        postsColumns() {
            let columns = [
                {
                    name: 'thumbnail',
                    label: ' ',
                    type: 'thumbnail',
                    sort: false,
                    value: (post) => {
                        return post.display_url
                    }
                },
                {
                    name: 'tooltip',
                    label: '',
                    type: 'tooltip',
                    icon: 'exclamation',
                    iconClass: 'text-black cursor-pointer',
                    containerClass: 'bg-yellow-exclamation rounded-full h-6 w-6 flex items-center justify-center',
                    text: 'This content needs your attention. Click here to edit the stats.',
                    sort: false,
                    onClick: (post) => {
                        this.editPost(post);
                    },
                    visible: (post) => {
                        return (post.is_video && post.views <= 0) || !post.media.length;
                    }
                },
                {
                    name: 'influencer-name',
                    label: 'Creator',
                    columnClass: () => {
                        return 'text-purple-m-secondary text-ps line-clamp-1'
                    },
                    type: 'string',
                    value: (post) => {
                        return this.parseUnicodeCharacters(post.profile_item.full_name !== '' ? post.profile_item.full_name : post.profile_item.username) 
                    },
                    span: {
                        text: 'Invite this user to login',
                        class: 'italic cursor-pointer text-sm',
                        visible: (post) => { return this.showInviteLabelFromPost(post); },
                        action: (post) => { this.sendInvitation(post.network_id, post.user); }
                    }
                },
                {
                    name: 'platform',
                    label: 'Platform',
                    type: 'networks',
                    currentValues: this.networksValues,
                    selector: false,
                    currentAccounts: (post) => {
                        return [post.network_id];
                    },
                    value: (post) => {
                        return [{
                            id: post.id,
                            handle: post.profile_item.username,
                            network_id: post.network_id,
                            network: post.network,
                            social_url: post.network.name
                        }];
                    }
                },
                {
                    name: 'likes',
                    label: 'Likes',
                    type: 'number-format',
                    isBoosted: (post) => isPostPropertyBoosted(post.boosted_data, 'likes'),
                    breakdown: (post) => generateBreakdown(post, 'likes'),
                    value: (post) => (post.likes || 0) + getBoostedData(post.boosted_data, 'likes'),
                    onClick: (post) => {
                        if (this.changes.length) {
                            this.editPost(post);
                        }
                    }
                },
                {
                    name: 'comments',
                    label: 'Comments',
                    type: 'number-format',
                    isBoosted: (post) => isPostPropertyBoosted(post.boosted_data, 'comments'),
                    breakdown: (post) => generateBreakdown(post, 'comments'),
                    value: (post) => (post.comments || 0) + getBoostedData(post.boosted_data, 'comments'),
                    onClick: (post) => {
                        if (this.changes.length) {
                            this.editPost(post);
                        }
                    }
                },
                {
                    name: 'views',
                    label: 'Views',
                    type: 'number-format',
                    isBoosted: (post) => isPostPropertyBoosted(post.boosted_data, 'views'),
                    breakdown: (post) => generateBreakdown(post, 'views'),
                    value: (post) => (post.views || 0) + getBoostedData(post.boosted_data, 'views'),
                    onClick: (post) => {
                        if (this.changes.length) {
                            this.editPost(post);
                        }
                    }
                },
                {
                    name: 'shares',
                    label: 'Shares',
                    type: 'number-format',
                    isBoosted: (post) => isPostPropertyBoosted(post.boosted_data, 'shares'),
                    breakdown: (post) => generateBreakdown(post, 'shares'),
                    value: (post) => (post.shares || 0) + getBoostedData(post.boosted_data, 'shares')
                },
                {
                    name: 'saves',
                    label: 'Saves',
                    type: 'number-format',
                    isBoosted: (post) => isPostPropertyBoosted(post.boosted_data, 'saves'),
                    breakdown: (post) => generateBreakdown(post, 'saves'),
                    value: (post) => (post.saves || 0) + getBoostedData(post.boosted_data, 'saves')
                },
                {
                    name: 'followers',
                    label: 'Creator followers',
                    type: 'number-format',
                    value: (post) => {
                        return post.profile_item.total_followers || 0
                    },
                    onClick: (post) => {
                        if (this.changes.length) {
                            this.editPost(post);
                        }
                    }
                },
                {
                    name: 'updated_at',
                    label: 'Last update',
                    type: 'date',
                    value: (post) => {
                        return post.updated_at;
                    }
                },
                {
                    name: 'actions',
                    label: '',
                    type: 'actions',
                    actions: [
                        {
                            disabled: (post) => {
                                return !post.audience_data || !post.audience_data.user_id;
                            },
                            label: 'View Profile',
                            onClick: this.openProfile
                        },
                        {
                            label: 'View Content',
                            onClick: this.openPost
                        }
                    ]
                }
            ];

            let activeColumns = JSON.parse(JSON.stringify(this.report.settings.columns));
            if (Array.isArray(activeColumns)) {
                activeColumns.push({ active: true, id: 'profile' }, { active: true, id: 'actions' }, { active: true, id: 'tooltip' }, { active: true, id: 'updated_at' });
            }

            return columns.filter(column => {
                let inSettings = Array.isArray(activeColumns) ? activeColumns.filter(col => col.active && col.id === column.name) : [];
                return inSettings.length;
            });
        }
    },

    watch:  {
        reportSettings: {
            deep: true,
            handler: function (settings) {
                this.runChanging(settings);
            }
        },

        report: {
            deep: true,
            handler: function (report) {
                if (report.type === 2 || report.type === 3) {
                    this.report.allow_feedback = false;
                }
            }
        }
    },

    beforeMount() {
        if (!this.isUserAdminOrHigher) {
            this.$router.push('/home');
        }
    },

    mounted() {
        this.loading = true;
        this.getNetworks();
        this.getTags();
        this.getCreatorTags();
        if (this.$route.params.id) {
            this.getReport();
        } else {
            this.loading = false;
            this.report.allow_feedback = true;
        }

        if (this.$route.params.projectId) {
            this.getProject();
        }
    },

    methods: {

        onUpdatePostShowType(column, element, value){
            const report_user_id = element.report_user.id;
            if (report_user_id) {
                this.loading = true;
                // this endpoint will also build the report payload (with await), so we just need
                // to get the report afterwards
                this.$http.put(`/api/report-user/${report_user_id}`, {
                    post_show_type: value 
                }).then(({ data }) => {
                    if(isValidObject(data)){
                        this.getReport();
                    }
                }).catch(err => {
                    notifyCatchError(err);
                }).finally(() => {
                    this.loading = false;
                });
            }else{
                console.warn('No report user ID set or user selected the existing post show type');
            }
        },

        async buildPayloadAndGetReport(){
            await this.saveReport(false); 
            await this.getReport(false);
        },

        platformsValue(element){

            if(element.pending){
                return element.searching ? 'Fetching audience data...' : 'Queued...';
            }

            function findSocialLogin(networkId){
                return element.social_logins.find(e => e.network_id == networkId)
            }

            function isConnected(social_login){
                return (social_login.access_token || social_login.long_lived_access_token) ? true : false;
            }

            function isConnectedByFbBusinessManager(social_login){
                return social_login.fb_business_token ? true : false; 
            }

            function connectionMessage(social_login){
                if(social_login.network_id == 6){
                    if(isConnected(social_login)) return 'Logged in with facebook, insights available';
                    if(isConnectedByFbBusinessManager(social_login)) return 'Connected by FB Business Manager, insights available';
                    return 'Not logged in with Facebook';
                }
                if(social_login.network_id == 14){
                    if(isConnected(social_login)) return 'Logged in with tiktok, insights available';
                    return 'Not logged in with tiktok';
                }
                return '';
            }

            return element.audience_data.map(audience => {
                
                let _isConnected = false;
                let _connectionMessage = '';
                
                // if its instagram or FB
                if(audience.network_id == 6 || audience.network_id == 2){
                    const found_social_login = findSocialLogin(6);
                    if(found_social_login){
                        _isConnected = isConnected(found_social_login) || isConnectedByFbBusinessManager(found_social_login);
                        _connectionMessage = connectionMessage(found_social_login)
                    }
                }

                // if its tiktok
                if(audience.network_id == 14){
                    const found_social_login = findSocialLogin(14);
                    if(found_social_login){
                        _isConnected = isConnected(found_social_login);
                        _connectionMessage = connectionMessage(found_social_login)
                    }
                }

                return {
                    id: audience.id,
                    handle: audience.social_url,
                    network_id: audience.network_id,
                    direct: true,
                    social_url: audience.social_url,
                    check_connected: true,
                    connected: _isConnected,
                    connected_message: _connectionMessage
                };
            });
        },

        async attachExistent(post) {
            this.openManualPost = false;
            this.savingItemCount = [post.url];
            await this.$http.post(`/api/reports/${this.report.id}/setPostId`, { post_id: post.id });

            this.savingItemCount = [];
            this.$notify({ title: 'Success', text: `Content saved successfully`, type: 'success' });
            this.newPost.link = '';
            this.getReport(true, 500);
        },

        handleExistingUsername(users) {
            this.disabledForm = true;
            
            if (users && Array.isArray(users) && users.length) {
                let user = users[0];
                this.existingHandle = user;
                let inReport = this.report.influencers.find(u => u.id == user.id);
                if (inReport) {
                    this.$notify({ title: 'Warning', text: 'This creator is already attached to this report', type: 'warn' });
                } else {
                    this.promptExists = true;
                }

                this.exists = true;
            } else {
                this.cancelExists();
            }
        },

        acceptExisting() {
            let {id} = this.existingHandle;
            this.cancelExists();
            this.closeAddEditCreatorModal();
            this.addCreator({ user: { id }})
        },

        cancelExists() {
            this.disabledForm = false;
            this.exists = false;
            this.promptExists = false;
            this.existingHandle = null;
        },

        cancelHandle() {
            this.requestHandle = false;
            this.userHandle = '';
        },

        showInviteLabelFromPost(post) {
            if (post.user) {
                if (post.user.social_logins.length) {
                    let postNetworkId = post.network_id;
                    if(postNetworkId == 2){
                        postNetworkId = 6
                    }
                    let found = post.user.social_logins.find(s => s.network_id == postNetworkId);
                    if (found) {
                        return false;
                    }
                }
                return true;
            }

            return false;
        },

        cancelInvite() {
            this.platformIntent = {
                show: false,
                user: null,
                networkId: null,
                networkLabel: null
            };
        },

        sendInvitation(network_id, user) {
            let networkId = null;
            let networkLabel = null;

            if (network_id == 2) {
                networkId = 6;
                networkLabel = 'Facebook';
            }

            if (network_id == 14) {
                networkId = 14;
                networkLabel = 'Tiktok';
            }
            
            if (networkId !== null && networkLabel !== null) {
                this.platformIntent = {
                    show: true,
                    user,
                    networkId,
                    networkLabel
                };
            }
        },

        addManualAudienceData(network_id, user){
            this.manualAudienceDataModalVisible = true
            this.manualAudienceDataModalUser = user
            this.manualAudienceDataModalNetworkId = network_id
        },

        viewAudienceData(user){
            this.viewAudienceDataModalVisible = true
            this.viewAudienceDataModalUser = user
        },

        openFeaturedPostsModal(user){
            this.featuredPostsModalVisible = true;
            this.featuredPostsModalUser = user;
        },

        openNetworksModal(user){
            this.networksModalVisible = true;
            this.networksModalUser = user;
        },

        showChanges(changes) {
            this.changes = changes;
            this.getReport(false);
        },

        changePage(page) {
            this.itemsPage = page;
        },

        removePending(id, cancel = false) {
            let postIndex = this.pendingPosts.findIndex(p => p.id == id);
            if (postIndex > -1) {
                this.pendingPosts.splice(postIndex, 1);
            }
            if (!cancel) {
                this.getReport(false);
            }
        },

        refreshGroups() {
            this.groupsKey = !this.groupsKey;
            this.groupsRefreshed = true;
        },

        async getTags(post) {
            const { data } = await this.$http.get(`/api/tags?type=reports`);
            this.tags = data.map(tag => {
                return {
                  value: tag.id,
                  label: tag.name
                }
            });
        },

        async getCreatorTags() {
            const { data } = await this.$http.get(`/api/tags?type=users`);
            this.creatorTags = data.map(tag => {
                return {
                  value: tag.id,
                  label: tag.name
                }
            });
        },

        editPost(post) {
            this.currentPost = this.report.posts.find(p => p.id === post.id);
            if (this.currentPost) {
                this.editingPost = true;
            }
        },

        openPostAdsModal(post) {
            this.currentPost = this.report.posts.find(p => p.id === post.id);
            if (this.currentPost) {
                this.postAdsModalVisible = true;
            }
        },

        cancelEditingPost() {
            this.currentPost = {profile_item: {}};
            this.addEditCreatorModalVisible = false;
            this.editingPost = false;
        },

        finishUpdate(post) {
            if (this.changes.length) {
                let change = this.changes.find(c => c.new.id === post.id);
                if (change) {
                    change.new = post;
                }
            }
            this.cancelEditingPost();
            this.getReport();
        },

        async getNetworks() {
            const { data } = await this.$http.get('/api/userDeliverables/networks');
            this.networksValues = data.map(network => {
                network.value = network.id;
                network.label = network.display_name;
                return network;
            });
        },

        async uploadBrandLogo(e) {
            this.uploadingFile = true;
            let fileData = new FormData();
            fileData.append('image', e.target.files[0]);

            this.$http.post(`/api/files/brand/logo`, fileData).then(async ({data}) => {
                this.$notify({ title: 'Success', text: 'Brand logo submitted successfully', type: 'success' });
                this.report.brand.logo = data.url;
            }).catch((err) => {
                console.log('upload logo error', err.response);
                this.$notify({ title: 'Something went wrong while uploading logo.', text: 'Try again later', type: 'warn' });
            }).finally(() => {
                this.uploadingFile = false;
            });
        },

        updateReport() {
            this.openManualPost = false;
            this.getReport();
        },

        creatorReach(creator) {
            let networks = getObjectValue(creator, 'report_user.networks')
            let computedAudience = creator.audience_data.filter(network => {
                return ((networks === null) || networks.includes(network.network_id)) && network.payload;
            });

            let followers = 0;
            for (let network of computedAudience) {
                followers = followers + parseInt(network.payload.user_profile.followers);
            }
            return followers;
        },

        creatorEngagements(creator) {
            let currentLikes = 0;
            let likes = 0;
            if (creator.audience_data) {
                for (let audience of creator.audience_data) {
                    currentLikes = currentLikes + parseInt(getObjectValue(audience, "payload.user_profile.avg_likes", 0));
                }
            }

            // calculate avg likes with posts
            if(creator.audience_data) {
                let filledAudience = 0;
                for (let audience of creator.audience_data) {
                    const posts = getObjectValue(audience, "payload.user_profile.top_posts", []) 
                    if (posts.length) {
                        const avg = posts.map(post => {
                            return post.stat.likes
                        }).reduce((acc, cur) => acc + cur, 0) / posts.length 
                        likes = likes + avg
                        filledAudience++;
                    }
                }
                if (filledAudience > 0) {
                    likes = likes / filledAudience
                }
            }
            if (currentLikes > likes) {
                return currentLikes;
            }
            return Math.floor(likes);
        },

        openPost(post) {
            window.open(post.url);
        },

        openProfile(post) {
          window.open(`/creator/${post.audience_data.user_id}`);
        },

        async runSearch() {
            this.results = [];
            this.noResults = false;
            if (this.search !== '') {
                this.searching = true;
                const { data } = await this.$http.get(`/api/users/search?search=${this.search}&deep=true`);
                if (data) {
                    // map the results so it's like a "project_user" object
                    this.results = data.map(user => {
                        // by default, all networks will not be toggled
                        let toggles = {
                            'instagram': { id: 2, value: false },
                            'tiktok': { id: 14, value: false },
                            'youtube': { id: 3, value: false }
                        };
                        // if the user has "network_users" (social_accounts) set, then
                        // we will use that array to auto toggle all the networks found
                        if(isValidArray(user.social_accounts)){    
                            for (const key in toggles) {
                                if (Object.prototype.hasOwnProperty.call(toggles, key)) {
                                    const object = toggles[key];
                                    const found = user.social_accounts.find(e => e.network_id == object.id);
                                    if(found){
                                        toggles[key].value = true;
                                    }
                                }
                            }
                        }
                        return { 
                            id: user.id,
                            instagram: toggles['instagram'].value, 
                            tiktok: toggles['tiktok'].value,
                            youtube: toggles['youtube'].value,
                            user 
                        };
                    });
                    this.noResults = !data.length;
                    this.searching = false;
                }
            }
        },

        checkOptions(option) {
            let trusted = 0;
            for (let op in option) {
                if (option[op]) {
                    trusted++;
                }
            }

            return trusted > 0;
        },

        addNewFeedbackOption() {
            this.settings_data.feedback.push({
                id: this.settings_data.feedback.length + 1,
                active: true,
                label: this.newFeedbackOptionField,
                type: this.newFeedbackOptionType.id
            });
            this.cancelNewFeedbackOption();
        },

        cancelNewFeedbackOption() {
            this.newFeedbackOptionField = '';
            this.newFeedbackOptionType = null;
            this.newFeedbackOptionTypeKey = !this.newFeedbackOptionTypeKey;
        },
        async updateValues(value) {
            // only update the values on the frontend, and save the order on saveReport
            this.report.influencers = value;
        },

        async setAccounts({id}, accounts) {
            let influencer = this.report.influencers.find(i => i.id === id);
            if (influencer) {
                influencer.report_user.networks = accounts;
                await this.$http.post(`/api/reports/${this.$route.params.id}/edit-networks`, { id, networks: accounts });
            }
        },

        updateReportSettings() {
            let elements = {};
            let data = this.settings_data;
            for (let i in data) {
                let element = {};
                let parent = data[i];
                if (typeof parent === 'boolean' || ['feedback', 'groups', 'posts_limit', 'loading_type'].includes(i)) {
                    element = parent;

                    if (['posts_limit', 'loading_type'].includes(i)) {
                        elements[i] = element;
                        continue;
                    }
                }

                for (let j in parent) {
                    let item = parent[j];
                    if (typeof item === 'object') {
                        let itemObject = {};
                        for (let k in item) {
                            itemObject[k] = item[k];
                        }
                        element[j] = itemObject;
                    } else {
                        if (j.includes('audience')) {
                            element[j] = this.reportSettings[i]['audience'][j];
                        } else {
                            element[j] = this.reportSettings[i][j];
                        }
                    }
                }
                elements[i] = element;
            }
            this.settings_data = elements;
        },

        async saveGroups(groups, notify = true, skip = false) {
            this.report.settings.skip = skip;
            this.report.settings.groups = groups;
            this.saveReport(notify);
        },

        toggleOptions () {
            this.$forceUpdate();
            this.runChanging(this.reportSettings);
            this.updateReportSettings();
        },

        runChanging(settings) {
            this.changingSettings = true;
            this.$forceUpdate();
            for (let i in settings) {
                if (i === 'groups' || i === 'columns') {
                    continue;
                }
                for (let j in settings[i]) {
                    let item = settings[i][j];
                    if (typeof item === 'object') {
                        let full = true;
                        for (let k in item) {
                            if (!item[k]) {
                                full = false;
                            }
                        }

                        this.sections[i][j] = full;
                    }
                }
            }
        },

        toggleOption() {
            this.runChanging(this.reportSettings);
            this.updateReportSettings();
        },

        toggleSettings(parent, index) {
            let value = this.sections[parent][index];
            let items = this.reportSettings[parent][index];

            let parentItems = this.settings_data[parent];
            let childrenItems = parentItems[index];

            let values = childrenItems ? childrenItems : parentItems;

            for (let i in values) {
                if (items[i] !== undefined) {
                    items[i] = value;
                }
            }
            this.updateReportSettings();
        },

        toggleMenu(creator, op = true) {
            creator.showDropdown = op;
            this.$forceUpdate();
        },

        kebabToTitle(string) {
            return snakeCaseToTitle(string);
        },

        setAltImg (e) {
            e.target.src = require('../../assets/images/spinner.gif');
        },

        async getProject() {
            const { data } = await this.$http.get(`/api/projects/${this.$route.params.projectId}`);
            this.project = data;
            this.report.project_id = data.id;
        },

        async refreshPost(post){
            const reportType = this.report.type == 1 ? 'users' : this.report.type == 2 ? 'posts' : null;
            const reportId = this.$route.params.id;
            const postId = post.id;
            if (reportType && reportId && postId) {
                this.loading = true;
                this.$http.get(`/api/reports/${reportType}/${reportId}/refresh/${postId}`).then(({ data }) => {
                    this.$notify({title: 'Success', text: `Post was updated successfully`, type: 'success'});
                    if(data && data.changes && data.changes[0] && data.changes[0].new){
                        this.currentPost = data.changes[0].new;
                        this.postSummaryModalVisible = true
                    }
                    this.getReport();
                }).catch((err) => {
                    this.$notify({ title: 'Error', text: 'Something went wrong while updating the post. Try again later.', type: 'warn' });
                }).finally(() => {
                    this.loading = false;
                });
            }
        },

        async getAllInfluencerData() {
            const { data } = await this.$http.get(`/api/reports/v2/${this.$route.params.id}/all`);
            return data;
        },

        async getReport(loading = true, timeout = 0) {
            let fn = async () => {
                this.loading = loading;
                const { data } = await this.$http.get(`/api/reports/v2/${this.$route.params.id}`);
                this.report = data;
                this.report.allow_feedback = data.settings?.allow_feedback !== undefined ? data.settings.allow_feedback : true;
                this.report.platforms = data.settings?.platforms !== undefined ? data.settings.platforms : true;
                const influencers = await this.getAllInfluencerData();
                
                this.report.influencers = influencers ? influencers.map(influencer => {
                    return this.setCreator(influencer);
                }) : [];
                const posts_limit = data.settings.posts_limit;
                const loading_type = data.settings.loading_type;
                let settings = {...data.settings};
                if (settings.hide_resume == undefined || settings.hide_stats == undefined) {
                    settings.hide_resume = false;
                    settings.hide_stats = false;
                }
                if(settings.show_boosted_data == undefined){
                    settings.show_boosted_data = true;
                }
                if (settings.preview == undefined) {
                    settings.preview = false;
                }
                this.settings_data = settings;
                
                // reset tags
                this.selectedTags = [];
                if (data.report_tags) {
                    let tags = [];
                    data.report_tags.forEach(element => {
                        tags.push({
                            value: element.tag.id,
                            label: element.tag.name
                        })
                    });
                    this.selectedTags = tags
                }
                this.tagsKey = !this.tagsKey;

                this.loading = false;
                this.loaded = true;
                setTimeout(() => {
                    this.post_key = !this.post_key;
                    this.user_key = !this.user_key;
                    this.typeKey = !this.typeKey;
                    this.tagsKey = !this.tagsKey;
                    this.settings_data.posts_limit = posts_limit;
                    this.settings_data.loading_type = loading_type;
                    this.postsLimitKey = !this.postsLimitKey;
                    this.loadingTypeKey = !this.loadingTypeKey;
                    this.colorsKey++;
                }, 2500);
            };

            if (timeout > 0) {
                setTimeout(() => {fn()}, timeout);
            } else {
                fn();
            }
        },

        setCreator(creator) {
            creator.showDropdown = false;
            creator.pending = false;
            creator.searching = false;
            creator.description = creator.report_user.description;

            if (creator.audience_data) {
                for (let i in creator.audience_data) {
                    if (!creator.audience_data[i].payload && !creator.pending) {
                        creator.pending = true;
                    }
                }
            }

            let platforms = creator.social_accounts.filter(a => NETWORKS[a.network_id]).map(a => NETWORKS[parseInt(a.network_id)].display_name).join(', ');
            if (creator.pending) {
                platforms = 'Fetching audience data...';
            }
            creator.platforms = platforms;
            return creator;
        },

        async saveReport(notify = true) {
             try{

                this.loading = true
                
                // set influencers order
                if (this.report.id && this.report.influencers && Array.isArray(this.report.influencers)){
                    let influencers = this.report.influencers.map((val, key) => {
                        let {id, report_id, user_id} = val.report_user;
                        return { id, report_id, user_id, order: key };
                    });
                    await this.$http.post(`/api/reports/${this.report.id}/setOrder`, { influencers });
                }
                
                // prepare endpoint
                const endpoint = this.report.id ? `/api/reports/${this.report.id}/update` : `/api/reports/create`;
                console.log("fetching endpoint", endpoint);

                // format payload
                let payload = this.report;
                if (this.report.id) {
                    let { name, description, id, settings } = this.report;
                    payload = { name, description, id, settings };

                    if (this.report.type === 3) {
                        payload.brand = this.report.brand;
                    }
                }
                
                // format tags
                payload.tags = this.selectedTags.map(tag => tag.value);

                const { data } = await this.$http.post(endpoint, payload);

                let action = this.report.id ? 'updated' : 'created';
                if (notify) {
                    this.$notify({title: 'Success', text: `Report was ${action} successfully`, type: 'success'});
                }

                if (!this.report.id) {
                    data.influencers = [];
                    data.posts = [];
                    this.report = data;
                    this.$router.push(`/reports/${this.report.guid}/edit`);

                    this.$nextTick(() => {
                        document.querySelector('#influencer-portion').scrollIntoView({ behavior: 'smooth' });
                    });
                } else {
                    this.report.settings = data.settings;
                }
            }catch(err){
                this.$notify({ title: 'Error', text: 'Error saving! Please try again!', type: 'error' });
            }finally{
                this.loading = false
            }

        },

        getCompletedCustoms(creator, platforms) {
            let customs = [];
            for (let platform of platforms) {
                if (this.networkValues[platform]) {
                    customs.push(this.networkValues[platform]);
                }
            }

            return customs.filter(profile => {
                let hasHandle = profile.social_url !== '' && !!profile.social_url;
                let hasUsername = profile.payload.user_profile.username !== '' && !!profile.payload.user_profile.username;
                return profile.is_custom && hasHandle && hasUsername && !!profile.network_id.id;
            }).map(profile => {
                let {avg_likes, followers} = profile.payload.user_profile;
                profile.user_id = creator.id;
                profile.network_id = profile.network_id.id;
                profile.payload.user_profile.avg_likes = parseInt(avg_likes);
                profile.payload.user_profile.engagements = parseInt(avg_likes);
                profile.payload.user_profile.fullname = profile.payload.user_profile.username;
                profile.payload.user_profile.url = profile.social_url;
                profile.payload.user_profile.followers = parseInt(followers);
                return profile;
            });
        },

        addCreatorNetworkToggle(creator, key){
            const id = creator.user.id;
            console.log('addCreatorNetworkToggle', creator, key)
            const found = this.results.find(element => element.user.id == id);
            if(found){
                const index = this.results.findIndex(element => element.user.id == id);
                this.results[index][key] = this.results[index][key] ? !this.results[index][key] : true
                this.$forceUpdate();
            }
        },

        async addCreator(creator) {
            this.closeAddEditCreatorModal();
            let networks = [];
            if(creator.instagram == true){
                networks.push(2);
            }
            if(creator.tiktok == true){
                networks.push(14);
            }
            this.$swal.fire({
                title: `Do you want to fetch the creator's posts again or use the existing ones?`,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Fetch posts',
                cancelButtonText: 'Use existing ones',
                reverseButtons: true,
            }).then(async (result) => {
                let fetchSocialData = true;
                if(!result.isConfirmed){
                    fetchSocialData = false;
                }
                this.addingCreatorToReport = true;
                await this.$http.post(`/api/reports/${this.$route.params.id}/add-creator`, { 
                    id: creator.user.id,
                    networks,
                    fetchSocialData
                });
                this.addingCreatorToReport = false;
                this.$notify({ title: 'Success', text: `Creator added successfully. We will get the audience data in a few minutes`, type: 'success' });
                this.getReport();  
            });
        },

        onCreatedCreator(creator) {
            this.newCreator = creator
        },

        async addNewCreatorToReport(){
            try {
                this.savingCreator = true;
                await this.$http.post(`/api/reports/${this.$route.params.id}/add-creator`, {
                    id: this.newCreator.id,
                    description: this.newCreator.description,
                    fetchSocialData: this.isEditingCreator == true ? false : true,
                    buildPayload: this.isEditingCreator == true ? false : true
                });
                this.addEditCreatorModalVisible = false;
                this.newCreator = null;
                
                let msg = `Creator saved successfully. We will get the audience data in a few minutes.`
                if(this.isEditingCreator == true){
                    msg = `Creator edited successfully.`
                }
                this.$notify({ title: 'Success', text: msg, type: 'success' })
                
                this.getReport();
                this.isEditingCreator = false;
            }
            catch(e) {
                this.$notify({ title: 'Error', text: 'Error saving! Please try again!', type: 'error' });
                console.log(e);
            } finally {
                this.savingCreator = false;
            }
        },

        fixUrl(uri) {
            if (uri.includes('http://')) {
                uri = uri.replace('http://', 'https://');
            }

            if (!uri.includes('https://')) {
                uri = 'https://' + uri;
            }

            return uri;
        },

        // bookmarklet: uncomment
        // async handleLink(query = '', force = false) {
        //     let link = this.newPost.link;
        //     this.adviseTiktok = false;
        //     if (link.includes('tiktok')) {
        //         this.adviseTiktok = true;
        //     } else {
        //         this.savePost(query, force);
        //     }
        // },

        // bookmarklet: remove this function
        async savePost(query = '', force = false) {
            this.newPostError = '';
            this.attachment = null;
            this.requestHandle = false;
            this.missingMediaModalVisible = false;

            let link = this.newPost.link;
            let fixedLink = this.fixUrl(link);
            if (link !== fixedLink) {
                link = fixedLink;
                this.newPost.link = fixedLink;
            }

            if (this.invalidLink) {
                return null;
            }

            let payload = {...this.newPost};
            if (this.userHandle !== '') {
                payload.handle = this.userHandle;
            }

            try {
                this.preparingPost = true;
                this.savingItemCount.push(link);
                setTimeout(() => {
                    this.preparingPost = false;
                }, 1500);

                if (force) {
                    let join = query.includes('?') ? '&' : '?';
                    query = `${query}${join}force=true`
                }

                let { data } = await this.$http.post(`/api/reports/${this.$route.params.id}/add-post${query}`, payload);
                
                if (data.attachment !== undefined) {
                    this.attachment = data.attachment;
                } else {
                    if (!force && data.pending) {
                        if (!data.media.length) {
                            this.missingMediaModalVisible = true;
                            this.getReport();
                            // this.$notify({ title: 'Post saved with media not present', text: 'The media files are omitted from the service if the media contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.', type: 'warn' });
                            // this.pendingPosts.push(data);
                        } else {
                            // we no longer use this restorer
                            // let message = 'There are some fields that need your attention';
                            // // if (!data.has_thumbnail) {
                            // //    message = 'We could not get the thumbnail for this post. Please check it in order to submit a picture.'
                            // // }
                            // this.$store.dispatch('restorer/set', {
                            //     message,
                            //     success: 'Content added successfully',
                            //     fail: 'Content could not be added',
                            //     action: true,
                            //     forceFn: false,
                            //     data: { undoLabel: 'Check' },
                            //     fn: () => {
                            //         this.editPost(data);
                            //     }
                            // });
                            this.getReport();
                            this.p_post_key = !this.p_post_key;
                            this.currentPost = data;
                            this.postSummaryModalVisible = true
                        }
                    } else {
                        let title = 'Success';
                        let text = 'Content saved successfully';
                        // if (!data.has_thumbnail) {
                        //     title = text;
                        //     text = 'We could not get the thumbnail for this post. Please check it in order to submit a picture.'
                        // }
                        this.$notify({ title, text, type: 'success' });
                        this.newPost.link = '';
                        this.cancelHandle();
                        this.getReport(true, 500);
                    }
                }
            }
            catch(e) {
                let error = 'Error saving! Please try again!';
                if(e.response && e.response.data){
                    if (e.response.data.message) {
                        if (e.response.data.pendingHandle) {
                            this.requestHandle = true;
                        } else {
                            this.cancelHandle();
                            recommendationsAlert(this, e.response.data.message, e.response.data.recommendations);
                        }
                    } else if (e.response.data.error) {
                        if(e.response.data.errorCode && e.response.data.errorCode == 'post-already-linked'){
                            this.closeAddEditCreatorModal(true);
                            this.cancelHandle();
                            this.$notify({ text: e.response.data.error, type: 'warn' });
                        }else{
                            this.closeAddEditCreatorModal(true);
                            this.promptManual = true;
                            this.cancelHandle();
                            error = e.response.data.error;
                            this.$notify({ title: 'Error', text: error, type: 'error' });
                        }
                    }
                }
            }
            finally {
                let counterIndex = this.savingItemCount.indexOf(link);
                if (counterIndex > -1) {
                    this.savingItemCount.splice(counterIndex, 1);
                }
                this.preparingPost = false;
            }
        },

        // bookmarklet: uncomment & remove past "savePost" method
        // async savePost(query = '', force = false) {
        //     this.newPostError = '';
        //     this.adviseTiktok = false;
        //     this.attachment = null;
        //     this.requestHandle = false;
        //     this.missingMediaModalVisible = false;

        //     let link = this.newPost.link;
        //     let fixedLink = this.fixUrl(link);
        //     if (link !== fixedLink) {
        //         link = fixedLink;
        //         this.newPost.link = fixedLink;
        //     }

        //     if (this.invalidLink) {
        //         return null;
        //     }

        //     scrapperFn({instance: this, query, force, link: this.newPost.link, callback: this.processLink});
        // },

        // async processLink({query, force, link, scrapperId = null}) {
        //     let payload = {...this.newPost};
        //     if (scrapperId !== null) {
        //         payload.scrapperPostId = scrapperId;
        //     }
        //     if (this.userHandle !== '') {
        //         payload.handle = this.userHandle;
        //     }

        //     try {
        //         this.preparingPost = true;
        //         this.savingItemCount.push(link);
        //         setTimeout(() => {
        //             this.preparingPost = false;
        //         }, 1500);

        //         if (force) {
        //             let join = query.includes('?') ? '&' : '?';
        //             query = `${query}${join}force=true`
        //         }

        //         let {data} = await this.$http.post(`/api/reports/${this.$route.params.id}/add-post${query}`, payload);
        //         if (data.attachment !== undefined) {
        //             this.attachment = data.attachment;
        //         } else {
        //             if (!force && data.pending) {
        //                 if (!data.media.length) {
        //                     this.missingMediaModalVisible = true;
        //                     // this.$notify({ title: 'Post saved with media not present', text: 'The media files are omitted from the service if the media contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.', type: 'warn' });
        //                     // this.pendingPosts.push(data);
        //                 } else {
        //                     this.$store.dispatch('restorer/set', {
        //                         message: 'There are some fields that need your attention',
        //                         success: 'Content added successfully',
        //                         fail: 'Content could not be added',
        //                         action: true,
        //                         forceFn: false,
        //                         data: { undoLabel: 'Check' },
        //                         fn: () => {
        //                             this.editPost(data);
        //                         }
        //                     });
        //                     this.getReport(true, 1000);
        //                     this.p_post_key = !this.p_post_key;
        //                 }
        //             } else {
        //                 this.$notify({ title: 'Success', text: `Content saved successfully`, type: 'success' });
        //                 this.newPost.link = '';
        //                 this.getReport(true, 500);
        //             }
        //         }
        //     }
        //     catch(e) {
        //         let error = 'Error saving! Please try again!';
                
        //         if (e.response && e.response.data && e.response.data.message) {
        //             if (e.response.data.pendingHandle) {
        //                 this.requestHandle = true;
        //             } else {
        //                 this.cancelHandle();
        //                 recommendationsAlert(this, e.response.data.message, e.response.data.recommendations);
        //             }
        //         } else if (e.response && e.response.data && e.response.data.error) {
        //             this.closeAddEditCreatorModal(true);
        //             this.promptManual = true;
        //             this.cancelHandle();
        //             error = e.response.data.error;
        //             this.$notify({ title: 'Error', text: error, type: 'error' });
        //         }
        //     }
        //     finally {
        //         let counterIndex = this.savingItemCount.indexOf(link);
        //         if (counterIndex > -1) {
        //             this.savingItemCount.splice(counterIndex, 1);
        //         }
        //         this.preparingPost = false;
        //     }
        // },

        async removeCreator(creator) {
            try {
                this.removingCreator = true;
                let user = {
                    id: creator.id
                };
                await this.$http.post(`/api/reports/${this.$route.params.id}/remove-influencer`, user);
                this.report.influencers.splice(this.report.influencers.indexOf(creator), 1);
                this.$notify({ title: 'Success', text: `Creator deleted successfully`, type: 'success' });
            }
            catch(e) {
                this.$notify({ title: 'Error', text: 'Error deleting! Please try again!', type: 'error' });
                // this.newPostError = 'Error deleting! Please try again!';
                console.log(e);
            }
            finally {
                this.removingCreator = false;
            }
        },

        async removePostIntent(post) {
            if (this.isDefaultReport) {
                this.$swal.fire({
                    title: 'Are you sure you want to remove this content?',
                    text: this.isDefaultReport ? 'If you remove this content, it will be unlinked from it deliverable since this is a Deliverable Report' : 'You can add it again in the future',
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.removePost(post.id);
                    }
                });
            } else {
                this.removePost(post.id);
            }
        },

        async removePost(postId) {
            this.removingPost = true;
            this.$http.post(`/api/reports/${this.$route.params.id}/remove-post`, {
                id: postId
            }).then(({ data }) => {
                const index = this.report.posts.findIndex(post => {
                    return post.id == postId
                });
                if(index >= 0){
                    this.report.posts.splice(index, 1);
                }
                this.cleanGroups = !this.cleanGroups;
                this.$notify({ title: 'Success', text: `Post removed successfully`, type: 'success' });
            }).catch(err => {
                console.log(err);
                notifyCatchError(this.$notify, err, 'Error removing post from report, please try again later.')
            }).finally(() => {
                this.removingPost = false;
            });
        },

        editCreator(creator) {
            // close the options dropdown
            this.toggleMenu(creator, false);
            this.isEditingCreator = true;
            this.newCreator = creator
            this.addEditCreatorModalVisible = true;
        },

        editCreatorCustomFields(creator) {
            // close the options dropdown
            this.toggleMenu(creator, false);
            this.customFieldsCreator = creator;
            this.customFieldsModalVisible = true;
        },

        async reloadCreator(creator) {
            if (creator.pending) {
                this.reloadingCreator = true;
                creator.searching = true;
                creator.platforms = 'Searching';
                this.toggleMenu(creator, false);
                this.$forceUpdate();

                try {
                    let {data} = await this.$http.post(`/api/reports/${this.$route.params.id}/get-account`, {id: creator.id});

                    if (data && data.message) {
                      this.$notify({title: 'Success', text: data.message, type: 'success'});
                      await this.getReport();
                    } else {
                      this.$notify({ title: 'Error', text: 'We can not get the audience data. Try again alter', type: 'warn' });
                    }
                } catch (e) {
                    console.log(e);
                    creator.platforms = 'Error trying to get audience data';
                } finally {
                    this.reloadingCreator = false;
                }
                creator.searching = false;
                this.$forceUpdate();
            }
        },

        closeAddEditCreatorModal(open = false) {
            this.isEditingCreator = false;
            this.search = '';
            this.noResults = false;
            this.addCreatorTab = 'search';
            this.results = [];
            this.newCreator = null;

            let pinterest_handle = {...customNetwork(4)};
            let facebook_handle = {...customNetwork(6)};
            let twitter_handle = {...customNetwork(7)};
            let linkedin_handle = {...customNetwork(16)};
            let custom_handle = {...customNetwork(17)};

            this.networkValues = {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: '',
                pinterest_handle,
                facebook_handle,
                twitter_handle,
                linkedin_handle,
                custom_handle
            };

            this.creatorNetworkKey = !this.creatorNetworkKey;
            this.newCreatorError = '';

            this.addEditCreatorModalVisible = open;
        },

        async saveSettings() {
            let elements = {};
            for (let i in this.report.settings) {
                let element = {};
                let parent = this.report.settings[i];
                if (typeof parent === 'boolean' || i === 'feedback' || i === 'posts_limit' || i === 'loading_type') {
                    element = this.settings_data[i];
                } else {
                    for (let j in parent) {
                        let item = parent[j];
                        if (typeof item === 'object') {
                            let itemObject = {};
                            for (let k in item) {
                                itemObject[k] = item[k];
                            }
                            element[j] = itemObject;
                        } else {
                            if (j.includes('audience')) {
                                element[j] = this.reportSettings[i]['audience'][j];
                            } else {
                                element[j] = this.reportSettings[i][j];
                            }
                        }
                    }
                }
                elements[i] = element;
            }

            this.report.settings = elements;

            this.savingSettings = true;
            await this.saveReport();
            this.savingSettings = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .create-report-wrapper {
        .content-pane {
            @apply overflow-auto;
            height: calc(100vh - 65px);
        }
    }

    .report-settings{
        //
        > .header{
            @apply flex justify-between items-center gap-x-4 px-12 py-10;
            > .title-container{
                @apply flex items-center gap-x-4;
                > .icon{
                    @apply inline-flex items-center justify-center rounded-full bg-purple-m-main w-15 h-15;
                }
                > .title{
                    @apply text-h2 font-bold text-purple-m-secondary;
                }
            }
            > .type-updated-at{
                @apply flex flex-col text-center;
                > .type{
                    @apply italic;
                }
                > .updated-at{
                    > span{
                        @apply font-bold;
                    }
                }
            }
            > .actions{
                //
            }
        }
        > .content-pane{
            @apply flex-1 relative overflow-auto flex flex-col gap-8;
            max-height: calc(100vh - 140px);
            > .general{
                @apply px-12;
                > .general-grid{
                    @apply grid grid-cols-2 gap-8;
                    & .project-container{
                        @apply mb-4;
                        @media (min-width: 640px) {
                            @apply grid grid-cols-3 gap-4 items-start;
                        }
                        > label{
                            @apply block text-sm font-medium leading-5 text-gray-700;
                            @media (min-width: 640px) {
                                @apply mt-px pt-2;
                            }
                        }
                        > .name-container{
                            @apply mt-1;
                            @media (min-width: 640px) {
                                @apply mt-0 col-span-2;
                            }
                            > .name{
                                @apply max-w-lg block text-sm font-bold leading-5 text-gray-700;
                                @media (min-width: 640px) {
                                    @apply mt-px pt-2;
                                }
                            }   
                        }
                    }
                    > .field{
                        //
                        > .label{
                            @apply text-h6 font-bold mb-1;
                        }
                        > .create{
                            > .radio-container{
                                @apply flex items-center h-5 gap-2;
                                > input{
                                    @apply h-4 w-4 text-indigo-600 transition duration-150 ease-in-out;
                                }
                                > p{
                                    @apply text-gray-500;
                                }
                            }
                        }
                    }
                    > .settings-header{
                        @apply col-span-2 flex flex-col;
                        > .title{
                            @apply text-h3 font-bold text-purple-m-secondary
                        }
                        > .actions{
                            @apply py-3 flex gap-4;
                        }
                    }
                    
                    > .settings-content{
                        @apply col-span-2 grid gap-4;
                        &.collapsed{
                            @apply max-h-0 overflow-y-hidden;
                        }
                        > .brand-report-settings{
                            @apply col-span-2 grid grid-cols-4 gap-8;
                            > .field{
                                //
                                > .label{
                                    @apply text-h6 font-bold mb-1;
                                }
                                > .loader{
                                    @apply h-auto w-5;
                                }
                                > .logo{
                                    @apply w-32 h-32 rounded cursor-pointer border-2 border-dashed object-contain;
                                }
                                > .invisible-input{
                                    @apply invisible h-0;
                                }
                                
                            }
                        }
                        > .creator-report-settings{
                            @apply col-span-2;
                            > .label{
                                @apply text-h6 font-bold mb-2;
                            }
                            > .feedback-grid{
                                @apply grid grid-cols-2 gap-8;
                                > .fields{
                                    @apply w-full border px-4 py-3 rounded;
                                    @apply flex flex-col gap-y-1;
                                    > .input-container{
                                        @apply flex items-center h-5 gap-2;
                                    }
                                    > .checkbox-container{
                                        @apply ml-7;
                                    }
                                }
                                > .allow-feedback-fields{
                                    @apply w-full flex flex-col gap-3 border px-4 py-3 rounded;
                                    > .label{
                                        @apply text-sm font-bold mb-2;
                                    }
                                    > .actions{
                                        @apply flex gap-3 justify-end;
                                    }
                                }
                            }
                        }
                        > .general-settings{
                            @apply col-span-2;
                            > .label{
                                @apply text-h6 font-bold mb-2;
                            }
                            > .field-grid{
                                @apply grid grid-cols-2 gap-8 mb-4;
                                > .wrapper{
                                    @apply w-full border px-4 py-4 rounded;
                                    @apply flex flex-col gap-y-2;
                                    > .input-container{
                                        @apply flex items-center h-5 gap-2;
                                    }
                                }
                                > .wrapper-alt{
                                    @apply w-full rounded;
                                }
                            }
                        }
                        > .post-limit-settings{
                            @apply col-span-2;
                            > .label{
                                @apply text-h6 font-bold mb-2;
                            }
                            > .field-grid{
                                @apply grid grid-cols-2 gap-8 mb-4;
                                > .wrapper-alt{
                                    @apply w-full rounded;
                                }
                            }
                        }
                        > .audience-settings{
                            @apply col-span-2;
                            > .label{
                                @apply text-h6 font-bold mb-2;
                            }
                            > .item{
                                @apply items-baseline mt-3;
                                > .item-grid{
                                    @apply grid gap-x-8 gap-y-2 grid-flow-col grid-rows-5;
                                    > .item-container{
                                        @apply w-full border px-4 py-3 rounded;
                                        > .input-container{
                                            @apply relative;
                                        }
                                    }
                                }
                            }
                        }


                    }

                }
                
            }

            > .link-a-post-advice{
                @apply flex justify-between items-center gap-2 px-12;
            }
            > .add-content-section{
                @apply grid grid-cols-2 gap-2 px-12;
                > .header{
                    @apply col-span-2 flex justify-between items-center text-h3 font-bold text-purple-m-secondary;
                    > .actions{
                        @apply py-3 flex gap-4;
                    }
                }
                > .content{
                    @apply col-span-2;
                    > .label{
                        @apply text-h6 font-bold mb-1;
                    }
                }
                > .error{
                    @apply col-span-2 py-3 bg-red-500 text-white text-center;
                }
            }
            > .report-groups-container{
                @apply px-12;
            }
            > .creator-brand-table-header{
                @apply flex justify-between items-center px-12;
                > .title-container{
                    @apply text-h2 font-bold text-purple-m-secondary;
                    > span{
                        @apply text-gray-m-disable;
                    }
                }
                > .actions{
                    @apply py-3 flex gap-4;
                }
            }
            > .brand-report-add-content-section{
                @apply grid grid-cols-2 gap-8 px-12;
                > .header{
                    @apply col-span-2 flex justify-between items-center text-h3 font-bold text-purple-m-secondary;
                    > .actions{
                        @apply py-3 flex gap-4;
                    }
                }
                > .content{
                    @apply col-span-2;
                    > .label{
                        @apply text-h6 font-bold mb-1;
                    }
                }
                > .error{
                    @apply col-span-2 py-3 bg-red-500 text-white text-center;
                }
            }
            > .brand-report-posts-table-header{
                @apply flex justify-between items-center px-12;
                > .title-container{
                    @apply text-h2 font-bold text-purple-m-secondary;
                    > span{
                        @apply text-gray-m-disable;
                    }
                }
                > .actions{
                    @apply py-3 flex gap-4;
                }
            }

        }
    }
</style>
