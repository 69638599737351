<template>
    <div  
        class="networks-wrapper"
        :class="`${wrapperClass}`"
    >
        <!-- loading state -->
        <div 
            class="loader-container"
            :class="`${size}`"
            v-if="loading"
        >
            <div class="skeleton">
                <skeleton 
                    type="circle" 
                    :width="size == 'x-small' ? 5 : size == 'small' ? 8 : size == 'medium' ? 15 : 8"
                    :height="size == 'x-small' ? 5 : size == 'small' ? 8 : size == 'medium' ? 15 : 8"
                    class="mb-0"
                />
            </div>
            <div class="icon-wrapper">
                <div class="icon-container">
                    <base-icon 
                        class="text-white"
                        name="loader"  
                    />
                </div>
            </div>
        </div> 
        <!-- list -->
        <template v-else-if="isValidObject(accounts) || isValidArray(accounts)">
            <div
                v-for="account in accounts"
                :key="account.id"
                v-tooltip="tooltip(account)"
                :class="`account ${size} ${individualAction ? 'individual-action' : ''} ${(account.check_connected || checkConnection) ? `check-connected` : ``}`"
                @click="handleAccount(account)" 
            >
                <!-- connection status -->
                <div 
                    v-if="(account.check_connected || checkConnection)"
                    class="connection-status"
                    :class="{ 
                        'connected': (account.connected || account.is_connected), 
                        'not-connected': !(account.connected || account.is_connected), 
                        'small': size === 'small'
                    }" 
                >   
                    <div @click.stop="invite(account)" class="invite-button">
                        Invite
                        <base-icon name="mail" :size="3" />
                    </div>
                    <base-icon 
                        class="exclamation-icon" 
                        :name="(account.connected || account.is_connected) ? 'check' : 'exclamation'" 
                        :size="size === 'small' ? 3 : 4" 
                    />
                </div>
                
                <!-- network-icon -->
                <base-icon 
                    :name="getNetworkName(account.network_id)" 
                    default-icon="world" 
                    :style="size === 'small' ? `color:${getColor(account.network_id)}` : ''"
                />
                
                <!-- individual action -->
                <div @click="(e)=>e.stopPropagation()">
                    <base-icon 
                        v-if="individualAction && individualActionIcon !== '' && account.social_url !== ''" 
                        :name="individualActionIcon"
                        :style="`color: #ff7878;position: absolute;z-index: 40;right: -0.5em;top: -0.4em;`"
                        @action="runIndividualAction(account)"
                    />
                </div>
                
                <!-- This used to have: currentAccounts !== null  -->
                <div v-if="selector" class="absolute -top-4">
                    <toggle-button 
                        :value="isActive(account.network_id)" 
                        @toggled="toggleActive(account)" 
                    />
                </div>

            </div>
        </template>
        <!-- empty state -->
        <div v-else>-</div>
        <send-invite-modal
            @close="sendInviteModalVisible = false"
            :visible="sendInviteModalVisible"
            :networkLabel="inviteNetwork.display_name"
            :networkId="inviteNetwork.id"
            :user="user"
        />
    </div>
</template>

<script>
import { isValidArray, isValidObject } from '../common';
import SendInviteModal from '../components/SendInviteModal.vue'
export default {
    props: {
        accounts: {
            type: Array
        },
        user: {
            type: Object,
            required: false,
            default: function () {
                return null;
            }
        },
        theme: {
            type: String,
            default: 'light'
        },        
        wrapperClass: {
            type: String,
            default: 'flex gap-6'
        },
        size: {
            type: String,
            required: false,
            default: 'medium'
        },
        selector: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        checkConnection: {
            type: Boolean,
            default: false
        },
        // selected value
        currentAccounts: {
            type: [Array, Object],
            default: function () {
                return null;
            }
        },
        // network options
        currentValues: {
            type: Array,
            default: function () {
                return [];
            }
        },
        passedNetworks: {
            type: Array,
            default: function () {
                return [];
            }
        },
        individualAction: {
            type: Boolean,
            default: false
        },
        individualActionIcon: {
            type: String,
            default: ''
        }
    }, 
    components: {
        SendInviteModal
    },
    data() {
        return {
            networks: this.currentValues,
            inviteNetwork: {},
            sendInviteModalVisible: false,
            isValidObject,
            isValidArray
        }
    },

    mounted() {
        if (!this.networks.length) {
            this.getNetworks();
        }
    },

    methods: {
        runIndividualAction(account) {
            this.$emit('individual-action', account);
        },

        async getNetworks() {

            if ( this.passedNetworks.length > 0 ) {
                this.networks = this.passedNetworks;
            } else {
                const { data } = await this.$http.get('/api/userDeliverables/networks');
                this.networks = data.map(network => {
                    network.value = network.id;
                    network.label = network.display_name;
                    return network;
                });
            }
        },

        getNetworkName(networkId) {
            let network = this.getNetwork(networkId);
            return network ? network.name : '';
        },

        getColor(networkId) {
            let network = this.getNetwork(networkId);
            let color = network ? network.color : '#000000';
            return color;
        },

        isActive(networkId) {
            if(this.currentAccounts !== null){
                let network = this.getNetwork(networkId);
                return network && this.currentAccounts.includes(network.id);
            }
            return true;
        },

        getNetwork(networkId) {
            if (typeof networkId === 'object' && networkId !== null) {
                networkId = parseInt(networkId.id);
            }
            return this.networks.find(network => {
                return network.id === networkId;
            });
        },

        invite(account){
            if((account.check_connected || this.checkConnection) && !(account.connected || account.is_connected)) {
                const networkId = account.network_id === 2 ? 6 : account.network_id  // if its IG we want them to log in with Facebook 
                const network = this.getNetwork(networkId)
                this.inviteNetwork = network
                return this.sendInviteModalVisible = true
            }
        },

        toggleActive(account){
            let accounts = this.accounts.map(a => a.network_id).map(a => {
                if (typeof a === 'object' && a !== null) {
                    return parseInt(a.id);
                }
                return a;
            });

            if (this.currentAccounts !== null) {
                accounts = this.currentAccounts;
            }

            let currentAccount = this.getNetwork(account.network_id);
            if (currentAccount) {
                if (accounts.includes(currentAccount.id)) {
                    let index = accounts.findIndex(a => a === currentAccount.id);
                    accounts.splice(index, 1);
                } else {
                    accounts.push(currentAccount.id);
                }
            }

            this.$emit('set-accounts', accounts);
            
        },

        handleAccount(account) {
            if (!this.disabled) {
                this.openAccount(account);
            }
        },

        openAccount(account) {
            let link = '#';
            if (account.direct) {
                link = account.uri || account.social_url || account.handle || account.social_id;
            } else {
                if(account.network && account.network.name){
                    link = `https://${account.network.name}.com/${this.getHandle(account)}`;
                }else if(account.network_id){
                    link = `https://${this.getNetworkNameById(account.network_id)}.com/${this.getHandle(account)}`;
                }
            }
            window.open(link, '_blank');
        },

        getHandle(account) {
            let handle = account.network && account.network.name === 'tiktok' ? '@' : '';
            let username = account.handle ? account.handle : (account.social_id ? account.social_id : '');
            handle = handle + username;
            return handle;
        },

        getNetworkNameById(network_id){
            const found = this.networks.find(element => element.id === network_id)
            if(found){
                return found.name
            }
        },

        tooltip(account){
            if(!account.handle) return {}
            const { check_connected, connected, is_connected, connected_message } = account
            let content = `<div>${account.handle}</div>`;
            if(check_connected || this.checkConnection){
                let msg
                // if there is already a message set, use that one
                if(connected_message){
                    msg = connected_message
                }else{
                    if(connected || is_connected){
                        msg = `<div>Connected</div>`
                    }else{
                        msg = `<div>Not connected</div>`
                    }
                }
                content = content + msg
            }

            return {
                html: true,
                content: content,
                placement: 'left',
                classes: 'bg-black text-white rounded py-1 px-2 text-xs mr-4 mb-6', 
                delay: {
                    show: 100, 
                    hide: 50 
                } 
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .networks-wrapper{
        @apply flex items-center;
        max-width: 400px;
        .loader-container{
            @apply relative;
            &.x-small{
                @apply w-5 h-5;
            }
            &.small{
                @apply w-8 h-8;
            }
            &.medium{
                @apply w-15 h-15;
            }
            .skeleton{
                @apply absolute;
            }
            .icon-wrapper{
                @apply absolute w-full h-full;
                .icon-container{
                    @apply flex w-full h-full items-center justify-center;
                }
            }
        }
        .account{
            @apply inline-flex cursor-pointer items-center justify-center rounded-full bg-white;
            &.individual-action{
                @apply relative;
            }
            &.check-connected{
                @apply relative;
            }
            &.x-small{
                @apply w-5 h-5;
            }
            &.small{
                @apply w-8 h-8;
            }
            &.medium{
                @apply w-15 h-15;
            }
            .connection-status{
                @apply absolute w-5 h-5 rounded-full flex justify-center items-center;
                bottom: -2px;
                right: 0px;
                @apply transition-all duration-200;
                &.connected{
                    @apply bg-green-500
                }
                &.not-connected{
                    @apply bg-yellow-300
                }
                &.small{
                    @apply w-4 h-4;
                    bottom: -10px;
                    right: -6px;
                }
                .exclamation-icon{
                    @apply flex;
                }
                .invite-button{
                    @apply hidden;
                }
            }
            &:hover{
                .connection-status.not-connected{
                    width: 60px;
                    &:hover{
                        filter: brightness(0.8);
                    }
                    .invite-button{
                        @apply w-full h-full flex justify-center items-center gap-x-1 text-pxs font-bold;
                    }
                    .exclamation-icon{
                        @apply hidden;
                    }
                }
            }
        }
    }

</style>