<template>
    <div v-if="!loading">
        <div class="flex">
            <div class="w-1/3">
                <div class="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                    <img class="h-48 w-48 flex-none rounded-full bg-gray-50" :src="creator.avatar" alt="" />
                </div>
            </div>
            <div class="w-2/3" v-if="hasAudienceData">
                <h4 class="text-lg font-bold">{{ [creator.first_name, creator.last_name].join(" ") }}</h4>
                <div class="mt-2 space-x-8 flex">
                    <p>
                        <span class="uppercase font-bold">{{ reach | numeral("0.0a") }} </span> reach
                    </p>
                    <p v-if="avgEngagements.engagements">
                        <span class="uppercase font-bold">{{ Math.floor(avgEngagements.engagements / userPosts.length) | numeral("0.0a") }} </span>
                        average engagements
                    </p>
                    <p v-if="avgEngagements.views">
                        <span class="uppercase font-bold">{{ Math.floor(avgEngagements.views / userPosts.length) | numeral("0.0a") }} </span>
                        average views
                    </p>
                    <!-- <p><span class="uppercase font-bold">{{ creator.cpm }} </span> cpm</p> -->
                </div>
                <p class="mt-2 text-sm" v-html="userBio"></p>
            </div>
            <div class="w-2/3" v-else>
                <template v-if="creator.social_logins && creator.social_logins.length > 0">
                    <div class="rounded-md bg-yellow-100 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        fill-rule="evenodd"
                                        d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div class="ml-2">
                                <h3 class="text-sm font-medium text-yellow-800">Audience Data Missing!</h3>
                                <div class="mt-2 text-sm text-yellow-700">
                                    <p>
                                        {{ creator.first_name }} is missing audience data.
                                        <a
                                            href="#"
                                            @click.prevent="audienceDataRefresh"
                                            class="ml-2 font-medium text-yellow-700 underline hover:text-yellow-600"
                                            >Attempt audience refresh</a
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="rounded-md bg-red-100 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path
                                        fill-rule="evenodd"
                                        d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div class="ml-2">
                                <h3 class="text-sm font-medium text-red-800">No Social Authentication Found!</h3>
                                <div class="mt-2 text-sm text-red-700">
                                    <p>
                                        {{ creator.first_name }} does not have any social accounts connected.
                                        <a href="#" @click.prevent="sendSocialEmail" class="ml-2 font-medium text-red-700 underline hover:text-red-600"
                                            >Send an email asking them to connect.</a
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-8 tabbed-view" v-if="hasAudienceData">
            <div class="px-4 sm:px-0">
                <tabs :tabs="['Example Posts', 'Audience Data', 'Additional Info']" v-model="currentTab" capitalize custom-class="ml-12 sm:ml-8" />
                <!-- <h3 class="text-base font-semibold leading-7 text-gray-900">Additional Information</h3> -->
                <!-- <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> -->
            </div>
            <div class="" v-if="currentTab === 'Additional Info'">
                <dl class="divide-y divide-gray-200">
                    <div class="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Location</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ userLocation }}</dd>
                    </div>
                    <div class="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Rate (original/final)</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 space-x-4">
                            <span>{{ creator.original_rate | numeral("$0,0") }}</span>
                            <span> / </span>
                            <span>{{ creator.final_rate | numeral("$0,0") }}</span>
                        </dd>
                    </div>
                    <div class="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Red Flags</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <span v-for="(flag, i) in creator.red_flags" :key="i" v-html="flag"></span>
                        </dd>
                    </div>
                    <div class="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4" v-for="(question, i) in creator.custom_questions" :key="i">
                        <dt class="text-sm font-medium leading-6 text-gray-900">{{ question.text }}</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ question.value }}</dd>
                    </div>
                    <div class="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Deliverables</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <div v-for="(deliverable, i) in creator.deliverables" :key="i">
                                <strong>{{ deliverable.quantity }}</strong> {{ deliverable.platform }} {{ deliverable.type }} / Usage:
                                {{ deliverable.usage_days }} days
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>
            <div v-else-if="currentTab === 'Example Posts'">
                <div class="">
                    <div class="media-row">
                        <div class="media-item" v-for="(post, i) in userPosts" :key="i" @click="showPost(i)">
                            <div class="media-wrapper">
                                <template v-if="post.video">
                                    <video :src="post.video" autoplay muted loop />
                                </template>
                                <template v-else>
                                    <div class="photo-bg" :style="{ 'background-image': `url(${post.display_url})` }"></div>
                                    <div class="media-img"><img :src="post.display_url" /></div>
                                </template>
                                <div class="stats space-x-4">
                                    <span class="flex items-center"><base-icon name="like" class="mr-1" /> {{ post.likes | numeral("0a") }}</span>
                                    <span class="flex items-center"><base-icon name="comments" class="mr-1" /> {{ post.comments | numeral("0a") }}</span>
                                    <span class="flex items-center" v-if="post.views && post.views > 0"
                                        ><base-icon name="views" class="mr-1" /> {{ post.views | numeral("0a") }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="currentTab === 'Audience Data'">
                <div class="flex items-center space-x-12 py-10">
                    <!-- Gender Chart -->
                    <gender-chart :data="audienceGenders" v-if="audienceGenders" />

                    <!-- Gender Age Chart -->
                    <gender-age-chart :data="audienceAges" v-if="audienceAges" />

                    <div>
                        <custom-bar-chart
                            title="Location"
                            :data="audienceGeo"
                        />
                    </div>
                </div>
            </div>
        </div>
        <post-details-modal
            :visible="postDetails.visible"
            :post="userPosts[postDetails.postIndex]"
            :existing-posts="userPosts.map((p) => p.post_identifier)"
            @close="postDetails.visible = false"
            @edited-post="updateEditedPost"
            @swapped-post="swappedPost"
            :can-edit="canEdit"
        />
    </div>
</template>

<script>
import Tabs from "@/components/Tabs.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import GenderAgeChart from "@/components/_charts/GenderAgeChart.vue";
import GenderChart from "@/components/_charts/GenderChart.vue";
import PostDetailsModal from "./PostDetailsModal.vue";
export default {
    components: {
        Tabs,
        BaseIcon,
        GenderAgeChart,
        GenderChart,
        PostDetailsModal,
    },
    props: {
        creator: {},
        loading: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        audienceData() {
            if (this.creator?.audience_data && this.creator.audience_data[0]) {
                return this.creator.audience_data[0].payload_v2 || {};
            }
            return {};
        },
        hasAudienceData() {
            return Object.keys(this.audienceData).length > 0;
        },
        audienceAges() {
            return this.audienceData?.audience_followers?.data?.audience_ages;
        },
        audienceGenders() {
            return this.audienceData?.audience_followers?.data?.audience_genders;
        },
        audienceGeo() {
            return this.audienceData?.audience_followers?.data?.audience_geo;
        },
        userLocation() {
            if (this.creator.city || this.creator.country) {
                return [this.creator.city, this.creator.country].filter((c) => c).join(" ");
            } else {
                return "Unknown";
            }
        },
        userBio() {
            if (this.creator.bio) {
                return this.creator.bio;
            }
            if (this.audienceData.user_profile?.description) {
                return this.audienceData.user_profile.description.replace(/\/n/gi, "<br>");
            }
            return "";
        },
        userPosts() {
            if (this.creator.audience_data && this.creator.audience_data[0]) {
                const posts = this.creator.audience_data.reduce((prev, curr) => {
                    if (curr.posts) {
                        prev = [...prev, ...curr.posts];
                    }
                    return prev;
                }, []);
                return posts
                    .sort((a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1))
                    .slice(0, 12)
                    .map((p) => {
                        // handles if post was replaced by user
                        if (p.post_replacements && p.post_replacements.length > 0) {
                            const oldPost = { ...p };
                            p = p.post_replacements[0].replacement;
                            p.original_post_id = oldPost.id;
                        }

                        if (p.is_video) {
                            const video = p.media.find((m) => m.mimetype === "video/mp4");
                            if (video) {
                                p.video = video.url;
                            }
                        }
                        return p;
                    });
            }

            return [];
        },
        reach() {
            return this.audienceData?.user_profile?.followers_count || 0;
        },
        avgEngagements() {
            const totalEngagements = this.userPosts.reduce(
                (prev, curr) => {
                    prev.engagements += (curr.likes || 0) + (curr.comments || 0);
                    prev.views += curr.views || 0;
                    return prev;
                },
                { engagements: 0, views: 0 }
            );

            return {
                engagements: totalEngagements.engagements,
                views: totalEngagements.views,
            };
        },
    },

    data() {
        return {
            currentTab: "Example Posts",
            postDetails: {
                visible: false,
                postIndex: null,
            },
        };
    },

    methods: {
        showPost(postIndex) {
            this.postDetails.postIndex = postIndex;
            this.postDetails.visible = true;
        },

        async audienceDataRefresh() {
            this.$emit("audience-refresh");
        },

        async sendSocialEmail() {
            console.log("this will send an email");
        },

        updateEditedPost(payload) {
            this.userPosts[this.postDetails.postIndex] = payload;
        },

        swappedPost() {
            this.postDetails.visible = false;
            this.$emit("reload-tab");
        },
    },
};
</script>

<style lang="scss">
.creator-report-wrapper {
    .media-row {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // flex-shrink: 0;
        position: relative;

        .media-item {
            padding: 5px;
            @apply w-1/3;
            height: 360px;

            &.taller {
                height: 520px;
            }
        }

        .media-wrapper {
            @apply relative;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .stats {
                display: none;
                position: absolute;
                top: 0%;
                left: 0;
                z-index: 10;
            }

            &:hover {
                .stats {
                    @apply text-white flex absolute w-full h-full justify-center items-center text-center;
                    z-index: 10;
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }

            video {
                object-fit: cover;
            }

            .photo-bg {
                @apply absolute;
                width: 100%;
                height: 100%;
                filter: blur(8px);
                z-index: 1;
            }

            .media-img {
                @apply absolute;
                display: flex;
                align-items: center;
                height: 100%;
                z-index: 10;
            }
        }
    }
}
</style>
