<template>
    <div class="additional-info">

        <div class="header">
            <div class="title">Additional Info</div>
            <div class="actions">
                <base-button
                    label="Add Info"
                    bold
                    icon="plus"
                    @action="createAdditionalInfoModalVisible = true"
                />
            </div>
        </div>
        
        <div class="content">
            <div 
                class="empty-state" 
                v-if="!isArray(userVal.additional_info)"
            >
                No additional info
            </div>
            <div 
                v-else 
                class="info-list"
            >
                <!-- view parsed info, edit info, delete them, create -->
                <div
                    class="info-item"
                    v-for="(info, index) in userVal.additional_info" 
                    :key="`info-${index}`"
                >
                    <div class="content-wrapper">
                        <div
                            class="exclusive" 
                            v-if="isValidArray(info.report_user_additional_info) && info.report_user_additional_info.filter(e => e.is_exclusive).length"
                        >
                            <base-icon name="locked" />
                            <div class="tooltip">
                                This custom field is only shown for a specific report. 
                            </div>
                        </div>
                        <div class="content">
                            <div class="name">{{info.name}}</div>
                            <!-- link type -->
                            <a 
                                v-if="info.type_detail.slug == 'link'" 
                                :href="info.parsed_value.split('|')[1]"
                                target="_blank"
                                class="value link"
                            >
                                {{info.parsed_value.split('|')[0]}}
                            </a>
                            <!-- other values -->
                            <div v-else class="value">{{ info.parsed_value || info.value }}</div>
                        </div>
                    </div>
                    <div class="actions">
                        <options-dropdown               
                            :options="[
                                {label: 'Edit', event: 'edit'},
                                {label: 'Delete', event: 'delete'}
                            ]"
                            @edit="openCreateAdditionalInfoModal(info)"
                            @delete="deleteAdditionalInfoIntent(info.id)"
                        />
                    </div>
                </div>

            </div>
        </div>

        <create-additional-info-modal
            :visible="createAdditionalInfoModalVisible"
            :user-id="user.id"
            :current-additional-info="targetAdditionalInfo"
            @reload="getAdditionalInfo()"
            @close="closeCreateAdditionalInfoModal()"
        />

    </div>
</template>
<script>
import CreateAdditionalInfoModal from './CreateAdditionalInfoModal.vue';
import { isArray, notifyCatchError } from '@/common';
import { isValidArray } from '../../../common';

export default {
    props: ['user'],
    components: {
        CreateAdditionalInfoModal
    },
    watch: {
        user(val){
            this.userVal = val;
        }
    },
    data(){
        return {
            userVal: this.user,
            createAdditionalInfoModalVisible: false,
            targetAdditionalInfo: null,
            isArray,
            isValidArray
        }
    },
    methods: {
        getAdditionalInfo(){
            this.loading = true;
            this.$http.get(`/user-additional-info/${this.user.id}?type=user`).then(({ data }) => {
                this.userVal.additional_info = data                
            }).catch(err => {
                console.log('getAdditionalInfo error', err);
            }).finally(() => {
                this.loading = false;
            });
            return;
        },

        deleteAdditionalInfoIntent(id){
            this.$swal.fire({
                title: 'Are you sure you want to delete this information for this creator?',
                text: `Changes made will also be reflected on this creator's reports`,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.deleteAdditionalInfo(id)
                }
            })
        },

        async deleteAdditionalInfo(id){
            this.loading = true;
            this.$http.delete(`/user-additional-info/${id}`).then(async (res) => {
                if(res.data){
                    this.$notify({title: 'Info deleted successfully', text: '', type: 'success'})
                    this.getAdditionalInfo();
                }
            }).catch((err) => {
                console.log('deleteAdditionalInfo error', err)
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false
            })
        },
        
        openCreateAdditionalInfoModal(info){
            this.targetAdditionalInfo = info;
            this.createAdditionalInfoModalVisible = true;
        },

        closeCreateAdditionalInfoModal(){
            this.targetAdditionalInfo = null;
            this.createAdditionalInfoModalVisible = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .additional-info{
        @apply p-12;
        > .header{
            @apply w-full mb-4 flex justify-between items-center;
            > .title{
                @apply font-bold text-h3;
            }
        }
        > .content{
            @apply mt-8 mb-10;
            > .empty-state{
                @apply;
            }
            > .info-list{
                @apply flex flex-wrap gap-3;
                > .info-item{
                    @apply py-3 px-6;
                    width: 320px;
                    @apply flex gap-x-10 justify-between items-center; 
                    @apply cursor-pointer rounded-lg transition-colors duration-300;
                    
                    > .content-wrapper{
                        @apply w-full overflow-hidden flex flex-col; 
                        > .exclusive{
                            @apply relative cursor-pointer;
                            &:hover{
                                > .tooltip{
                                    @apply visible;
                                }
                            }
                            > .tooltip{
                                @apply absolute invisible;
                                @apply text-pxs bg-black text-white p-2 rounded-lg;
                                top: 10px;
                                width: 200px;
                            }
                        }

                        > .content{
                            @apply overflow-hidden flex flex-col;
                            > .name{
                                @apply font-bold;
                            }
                            > .value{
                                @apply;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                &.link{
                                    @apply font-bold text-blue-500 cursor-pointer;
                                    &:hover{
                                        @apply opacity-70;
                                    }
                                }
                            }
                        }
                    }

                    > .actions{
                        @apply invisible;
                    }



                    &:hover{
                        @apply bg-gray-100;
                        > .actions{
                            @apply visible;
                        }
                    }
                }
            }
        }
    }
</style>
